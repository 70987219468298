import { _fetch, _getRequestConnectionURL, IConnection } from "./restUtils";
import { PermanentUserPreferences } from "../userPeferencesService/userPreferencesService";

enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

type IContentType = "application/x-www-form-urlencoded" | "application/json";

export interface IRequest extends IConnection {
  signal?: AbortSignal;
  headers?: {
    authorization?: string;
  };
  body?: {
    [key: string]: string | number | undefined;
  };
  contentType?: IContentType;
}

export class RestService {
  public static get = (props: IRequest) => {
    return RestService.execute(Method.GET, props);
  };

  public static post = (props: IRequest) => {
    return RestService.execute(Method.POST, props);
  };

  public static put = (props: IRequest) => {
    return RestService.execute(Method.PUT, props);
  };

  public static purge = (props: IRequest) => {
    return RestService.execute(Method.DELETE, props);
  };

  static execute = (method: Method, props: IRequest) => {
    const { signal, ...connection } = props;
    const requestUrl = _getRequestConnectionURL(connection);

    const headers = new Headers();
    headers.append("Accept", "application/json");

    if (props.headers?.authorization) {
      headers.append("Authorization", props.headers.authorization);
    } else {
      const existingToken = PermanentUserPreferences.get("", "token");
      headers.append("Authorization", "Bearer " + existingToken);
    }

    let body = undefined;

    if (props.body && !props.contentType) {
      props.contentType = "application/json";
    }

    if (props.contentType) {
      headers.append("Content-Type", props.contentType);

      switch (props.contentType) {
        case "application/json":
          body = JSON.stringify(props.body);
          break;

        case "application/x-www-form-urlencoded":
          body = Object.entries(props.body || {})
            .filter(([, eValue]) => eValue != null)
            .map(([eKey, eValue]: [string, string | number | undefined]) =>
              eValue != null ? encodeURIComponent(eKey) + "=" + encodeURIComponent(eValue) : ""
            )
            .join("&");
          break;

        default:
          break;
      }
    }

    const requestMeta: RequestInit = {
      method,
      signal,
      body,
      headers,
      mode: props.mode,
    };

    return _fetch(requestUrl, requestMeta);
  };
}
