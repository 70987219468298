import toast from "react-hot-toast";
import { PermanentUserPreferences } from "./../services/userPeferencesService/userPreferencesService";
import { RestEnv } from "../services/restService/restUtils";
import { RestService } from "../services/restService/restService";
import { useCallback, useEffect, useRef, useState } from "react";

export type IUser = {
  name: string;
  email: string;
  token: string;
  permissions: {
    isEquipmentInstaller?: boolean;
    isProjectPartner?: boolean;
    isSuperAdmin?: boolean;
    nexusCommandsAccessMode?: "rw" | "r";
  };
};

export function useUserLoginSystem() {
  const wasFetched = useRef(false);
  const [isLogged, setIsLogged] = useState<boolean | undefined>(undefined);
  const [user, setUser] = useState<undefined | IUser>(undefined);
  const [state, setState] = useState<"IDLE" | "AUTO-LOGINING" | "LOGINING">("IDLE");

  useEffect(() => {
    if (wasFetched.current) {
      return;
    }

    const prevToken = PermanentUserPreferences.get("", "token");

    if (prevToken) {
      setState("AUTO-LOGINING");
      RestService.get({ env: RestEnv.ACCESS, path: "/api/1.0/auth/refresh" }).then((result) => {
        if (result.isOk && result.data?.status === "success" && result.data?.token != null) {
          PermanentUserPreferences.set("", "token", result.data.token);
          setIsLogged(true);
          setUser({
            email: result.data?.user?.email || "",
            name: result.data?.user?.name || "",
            token: result.data?.token || "",
            permissions: {
              isEquipmentInstaller: result.data?.user?.isEquipmentInstaller,
              isProjectPartner: result.data?.user?.isProjectPartner,
              isSuperAdmin: result.data?.user?.isSuperAdmin,
              nexusCommandsAccessMode: result.data?.user?.nexusCommandsAccessMode,
              // nexusCommandsAccessMode: "r",
            },
          });
        } else {
          setIsLogged(false);
        }

        setState("IDLE");
      });

      // setTimeout(() => setState("IDLE"), 2000);
    } else {
      PermanentUserPreferences.purge("", "token");
      setIsLogged(false);
      setUser(undefined);
    }

    wasFetched.current = true;
  }, []);

  const logUserIn = useCallback((username: string, password: string) => {
    setState("LOGINING");
    RestService.post({
      env: RestEnv.ACCESS,
      path: "/api/1.0/auth/login",
      body: { username, password },
    }).then((result) => {
      if (result.isOk && result.data?.status === "success" && result.data?.token != null) {
        PermanentUserPreferences.set("", "token", result.data.token);
        setIsLogged(true);
        setUser({
          email: result.data?.user?.email || "",
          name: result.data?.user?.name || "",
          token: result.data?.token || "",
          permissions: {
            isEquipmentInstaller: result.data?.user?.isEquipmentInstaller,
            isProjectPartner: result.data?.user?.isProjectPartner,
            isSuperAdmin: result.data?.user?.isSuperAdmin,
            nexusCommandsAccessMode: result.data?.user?.nexusCommandsAccessMode,
            // nexusCommandsAccessMode: "r",
          },
        });
      } else {
        toast.error(result.errorDetails || "Failed to login! Unknown reason.");
      }

      setState("IDLE");
    });
    // setTimeout(() => setIsLogged(true), 2000);
  }, []);

  const logUserOut = useCallback(() => {
    RestService.post({ env: RestEnv.ACCESS, path: "/api/1.0/auth/logout" });
    PermanentUserPreferences.purge("", "token");
    setIsLogged(false);
    setUser(undefined);
  }, []);

  return {
    isLogged,
    logUserIn,
    logUserOut,
    state,
    user,
  };
}
