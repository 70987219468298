import ContentSelector from "../../../../../deviceList/_common/ContentSelector";
import HistoryToolbar from "../HistoryToolbar";
import Icon from "@mdi/react";
import { Fragment, useCallback, useEffect, useId, useMemo, useRef, useState } from "react";
import { generateOptions, processData } from "./legacy/LegacyChart.utils";
import { LegacyChart } from "./legacy/LegacyChart";
import { LoadingIcon } from "../../../../../../decorators/loading/Loading";
import { mdiDownload } from "@mdi/js";
import { RestEnv } from "../../../../../../services/restService/restUtils";
import { SummaryHistoryPercentage } from "../historySummaryPercentage/SummaryHistoryPercentage";
import { SummaryHistoryStats } from "../historySummaryStats/SummaryHistoryStats";
import { Transition } from "@headlessui/react";
import { useGetData } from "../../../../../../hooks/useGetData";
import { useMqttProperty } from "../../../../../../hooks/useMqttClient";
import { usePeakShaving } from "../usePeakShaving/usePeakShaving";
import { useTranslation } from "react-i18next";
import { type IPossibleIO, getDeviceTypeDef } from "../historySummaryStats/utils";
import type { IDevice, IDevicePlantType } from "../../../../../../types/Device";

export enum PeriodType {
  Day,
  Month,
  Year,
  Lifetime,
}

type IItem = {
  key: string;
  name: string;
  icon: string;
  colorClassName: string;
};

type IState = {
  selectedKey: string;
  selectedDate: Date;
  splitEnergy: boolean;
  shareLimits: boolean;
  key: number;
  importLimit: number | null;
  exportLimit: number | null;
};

export default function HistorySummary(props: { device: IDevice; type: PeriodType }) {
  const { t } = useTranslation();
  const componentId = useId();
  const items = useRef<IItem[]>(generateItems(props.device.device.power_plant_type));
  const [state, setState] = useState<IState>({
    selectedKey: items.current[0].key,
    selectedDate: new Date(),
    splitEnergy: true,
    shareLimits: false,
    key: Date.now(),
    importLimit: null,
    exportLimit: null,
  });

  const chartRef = useRef<any>(null);
  const peakShaveLimiter = usePeakShaving({ chartRef });

  const { data, isLoading, fetchData, _ts } = useGetData<any>({
    request: {
      env: RestEnv.HISTORY,
      path: `/api/1.0/history/${props.device.device.type}/${props.device.device.id}/${
        props.type === PeriodType.Lifetime ? "lifetime" : getPeriodUrlForPeriodDate(props.type, state.selectedDate)
      }`,
    },

    skipFetch: !state.selectedDate,
  });

  useMqttProperty("site", componentId, 60_000, (data: { import_limit: number; export_limit: number }) => {
    setState((prev) => ({ ...prev, importLimit: data.import_limit, exportLimit: data.export_limit * -1 }));
  });

  const options = useMemo(() => {
    if (!data) {
      return null;
    }

    const selectedKeyIndex = items.current.findIndex((i) => i.key === state.selectedKey) + 1;

    return generateOptions(
      selectedKeyIndex,
      processData(data, state.splitEnergy, props.type),
      state.splitEnergy,
      state.shareLimits,
      t,
      props.type,
      "en",
      state.selectedDate,
      undefined,
      props.device.device.power_plant_type,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.key, _ts]);

  const onNewDate = useCallback((newDateObj: { year: number; month: number; day: number }) => {
    setState((prevState) => ({
      ...prevState,
      key: Date.now(),
      selectedDate: new Date(newDateObj.year, newDateObj.month - 1, newDateObj.day),
    }));
  }, []);

  const refreshData = () => {
    fetchData();
  };

  useEffect(() => {
    if (props.device.device.type !== "ngenstar" || props.type !== PeriodType.Day) {
      peakShaveLimiter.hideImportLimits();
      peakShaveLimiter.hideExportLimits();
      return;
    }

    if ((state.selectedKey === "load" || state.selectedKey === "grid") && state.importLimit !== null) {
      peakShaveLimiter.showImportLimits({ importLimit: state.importLimit, divideBy1000: true });
    } else {
      peakShaveLimiter.hideImportLimits();
    }

    if (state.selectedKey === "grid" && state.exportLimit !== null) {
      peakShaveLimiter.showExportLimits({ exportLimit: state.exportLimit, divideBy1000: true });
    } else {
      peakShaveLimiter.hideExportLimits();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.selectedKey,
    state.shareLimits,
    state.importLimit,
    state.exportLimit,
    props.device.device.type,
    props.type,
    isLoading,
    chartRef.current?.container,
  ]);

  return (
    <>
      {props.type !== PeriodType.Lifetime && (
        <HistoryToolbar
          type={getTypeFromPeriod(props.type) as any}
          key={"historyToolbar"}
          onNewDate={onNewDate}
          refreshData={refreshData}
        />
      )}

      <div className="flex flex-col justify-between gap-4 sm:flex-row">
        <div className="grow">
          <ContentSelector
            items={items.current}
            selectedKey={state.selectedKey}
            onSelection={(newKey) => setState({ ...state, selectedKey: newKey, key: Date.now() })}
          />
        </div>

        <div className="flex items-center gap-4">
          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id="extremes"
                name="extremes"
                type="checkbox"
                checked={state.shareLimits}
                onChange={(e) => setState({ ...state, shareLimits: e.target.checked, key: Date.now() })}
                className="h-4 w-4 rounded border-brand-300 text-ngen hover:text-emerald-400 focus:outline-none focus:ring-0 dark:bg-brand-800"
              />
            </div>
            <div className="ml-2 text-sm leading-6">
              <label htmlFor="extremes" className="font-medium">
                Share extremes
              </label>
            </div>
          </div>

          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id="splitEnergy"
                name="splitEnergy"
                type="checkbox"
                checked={state.splitEnergy}
                onChange={(e) => setState({ ...state, splitEnergy: e.target.checked, key: Date.now() })}
                className="h-4 w-4 rounded border-brand-300 text-ngen hover:text-emerald-400 focus:outline-none focus:ring-0 dark:bg-brand-800"
              />
            </div>
            <div className="ml-2 text-sm leading-6">
              <label htmlFor="splitEnergy" className="font-medium">
                Show sources and destinations
              </label>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div style={{ height: "555px" }} className="flex h-full w-full items-center justify-center">
          <LoadingIcon />
        </div>
      ) : (
        <>
          <Transition
            key={`${_ts}_${state.key}`}
            as={Fragment}
            enter="transition ease-out duration-1000"
            enterFrom="transform opacity-0"
            enterTo="transform opacity-100"
            leave="transition ease-in duration-700"
            leaveFrom="transform opacity-100"
            leaveTo="transform opacity-0"
            appear
            show
          >
            <div>
              <div className="mt-2 rounded bg-brand-950 p-4">
                <LegacyChart options={options || {}} chartRef={chartRef} />
              </div>
              <button
                className="mt-2 flex w-full items-center justify-center gap-1 rounded border border-brand-200 bg-brand-100 px-4 py-2 font-bold  shadow-sm hover:bg-brand-200 dark:border-brand-800 dark:bg-brand-900  dark:hover:bg-brand-800"
                onClick={() => {
                  chartRef.current?.chart.downloadCSV?.(getPeriodUrlForPeriodDate(props.type, state.selectedDate));
                }}
              >
                <Icon path={mdiDownload} className="-mb-1 h-4 w-4" />
                Download .csv
              </button>
            </div>
          </Transition>
          <SummaryHistoryStats
            impact={data?.summary?.impact}
            energy={data?.summary?.energy}
            selectedIoKey={state.selectedKey as any}
            energySources={data?.summary?.energy_sources}
            energyDestinations={data?.summary?.energy_destinations}
            devicePlantType={props.device.device.power_plant_type}
          />
          <SummaryHistoryPercentage
            selectedIoKey={state.selectedKey as any}
            energySources={data?.summary?.energy_sources}
            energyDestinations={data?.summary?.energy_destinations}
            devicePlantType={props.device.device.power_plant_type}
          />
        </>
      )}
    </>
  );
}

function generateItems(plantType: IDevicePlantType | undefined): IItem[] {
  const items = ["load", "photovoltaic", "battery", "grid"] as IPossibleIO[];

  return items
    .map((type) => getDeviceTypeDef({ type, val: 0, plantType }))
    .map((i) => ({ key: i.key, name: i.label, icon: i.icon, colorClassName: i.className }));
}

function getTypeFromPeriod(period: PeriodType): "day" | "month" | "year" | "lifetime" {
  switch (period) {
    case PeriodType.Day:
      return "day";
    case PeriodType.Month:
      return "month";
    case PeriodType.Year:
      return "year";
    case PeriodType.Lifetime:
      return "lifetime";
    default:
      return "day";
  }
}

function getPeriodUrlForPeriodDate(period: PeriodType, date: Date): string {
  switch (period) {
    case PeriodType.Day:
      return `daily/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    case PeriodType.Month:
      return `monthly/${date.getFullYear()}/${date.getMonth() + 1}`;
    case PeriodType.Year:
      return `yearly/${date.getFullYear()}`;
    default:
      return `daily/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
  }
}
