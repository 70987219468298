import React from "react";
import styles from "./DotSpinner.module.css";

const DotSpinner: React.FC = () => {
  return (
    <svg width="120" height="96" viewBox="0 28 120 96" xmlns="http://www.w3.org/2000/svg">
      <circle className={styles.circle} cx="12" cy="48" r="6" />
      <circle className={styles.circle + " " + styles.c1} cx="36" cy="48" r="6" />
      <circle className={styles.circle + " " + styles.c2} cx="60" cy="48" r="6" />
      <circle className={styles.circle + " " + styles.c3} cx="84" cy="48" r="6" />
      <circle className={styles.circle + " " + styles.c4} cx="108" cy="48" r="6" />
    </svg>
  );
};

export default DotSpinner;
