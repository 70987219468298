import Container from "../../../../../decorators/container/Container";
import ContentSelector from "../../../../deviceList/_common/ContentSelector";
import HistoryRaw from "./historyRaw/HistoryRaw";
import HistorySummary, { PeriodType } from "./historySummary/HistorySummary";
import { getStateContainerClass } from "../states/utils";
import { IDevice } from "../../../../../types/Device";
import { mdiChartLine } from "@mdi/js";
import { useState } from "react";

export default function History(props: { device: IDevice }) {
  const supportsRawHistory = props.device.device.type === "ngenstar" || props.device.device.type === "smart-meter";
  const [selectedKey, setSelectedKey] = useState(supportsRawHistory ? "raw" : "dailySummary");

  // Used for testing different power plant types
  // props.device.device.power_plant_type = "wind";

  return (
    <Container
      title="History"
      className={getStateContainerClass(undefined)}
      expandedStyle={{
        minHeight: selectedKey !== "raw" ? "930px" : "990px",
      }}
      icon={mdiChartLine}
      isCollapseable
    >
      <ContentSelector
        items={[
          { key: "raw", name: "Raw measurements", hidden: !supportsRawHistory },
          { key: "dailySummary", name: "Daily summary" },
          { key: "monthlySummary", name: "Monthly summary" },
          { key: "yearlySummary", name: "Yearly summary" },
          { key: "lifetimeSummary", name: "Lifetime summary" },
        ]}
        selectedKey={selectedKey}
        onSelection={setSelectedKey}
      />
      <div className=" mt-4 flex flex-col gap-2">
        {selectedKey === "raw" ? (
          <HistoryRaw device={props.device} />
        ) : (
          <HistorySummary key={selectedKey} device={props.device} type={getPeriodType(selectedKey)} />
        )}
      </div>
    </Container>
  );
}

function getPeriodType(key: string) {
  switch (key) {
    case "dailySummary":
      return PeriodType.Day;
    case "monthlySummary":
      return PeriodType.Month;
    case "yearlySummary":
      return PeriodType.Year;
    case "lifetimeSummary":
      return PeriodType.Lifetime;
    default:
      return PeriodType.Day;
  }
}
