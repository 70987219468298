import moment from "moment";
import StatusContainer from "../../../../../_common/StatusContainer";
import StatusValue from "../../../../../_common/StatusValue";
import { getStateContainerClass } from "../../utils";
import { mdiArrowBottomLeft, mdiArrowCollapseDown, mdiArrowSplitVertical, mdiArrowTopRight, mdiBatterySync } from "@mdi/js";
import { useId, useState } from "react";
import { useMqttProperty } from "../../../../../../../hooks/useMqttClient";

export default function BatteryConfigState() {
  const componentId = useId();

  const [state, setState] = useState<{
    isLoading: boolean;
    dateTime: string;
    minimum_soc: number | undefined;
    minimum_soc_on_grid: number | undefined;
    threshold_soc: number | undefined;
    maximum_soc: number | undefined;
  }>({
    isLoading: true,
    dateTime: "N/A",

    minimum_soc: undefined,
    minimum_soc_on_grid: undefined,
    threshold_soc: undefined,
    maximum_soc: undefined,
  });

  useMqttProperty(
    "battery-extended",
    componentId,
    10_000,
    (data: {
      minimum_soc: number;
      minimum_soc_on_grid: number;
      threshold_soc: number;
      maximum_soc: number;
      metadata: {
        utc_timestamp: number;
      };
    }) => {
      setState({
        minimum_soc: data.minimum_soc,
        minimum_soc_on_grid: data.minimum_soc_on_grid,
        threshold_soc: data.threshold_soc,
        maximum_soc: data.maximum_soc,

        dateTime:
          data.metadata.utc_timestamp != null
            ? moment.tz(data.metadata.utc_timestamp * 1000, "Europe/Ljubljana").format("DD.MM.yyyy HH:mm:ss")
            : "N/A",
        isLoading: false,
      });
    }
  );

  return (
    <>
      <StatusContainer
        title="Battery settings"
        dateTime={state.dateTime}
        icon={mdiBatterySync}
        className={getStateContainerClass("battery")}
      >
        <StatusValue
          isLoading={state.isLoading}
          value={state.minimum_soc}
          description="Minimum SOC"
          icon={mdiArrowBottomLeft}
          unit="%"
          editable={{
            dataKey: "minimum-soc",
            type: "numberInput",
          }}
        />
        <StatusValue
          isLoading={state.isLoading}
          value={state.maximum_soc}
          description="Maximum SOC"
          icon={mdiArrowTopRight}
          unit="%"
          editable={{
            dataKey: "maximum-soc",
            type: "numberInput",
          }}
        />
        <StatusValue
          isLoading={state.isLoading}
          value={state.threshold_soc}
          description="Threshold SOC"
          icon={mdiArrowSplitVertical}
          unit="%"
          userEditable
          editable={{
            dataKey: "threshold-soc",
            type: "numberInput",
            validation: {
              min: {
                value: 12,
                message: "Value must be bigger than 12",
              },
              max: {
                value: 100,
                message: "Value must be smaller than 100",
              },
            },
          }}
        />
        <StatusValue
          isLoading={state.isLoading}
          value={state.minimum_soc_on_grid}
          description="Minimum SOC on grid"
          icon={mdiArrowCollapseDown}
          unit="%"
          editable={{
            dataKey: "minimum-soc-on-grid",
            type: "numberInput",
          }}
        />
      </StatusContainer>
    </>
  );
}
