import Highcharts, { Options, TooltipOptions } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { forwardRef } from "react";
import { PermanentUserPreferences } from "../../../services/userPeferencesService/userPreferencesService";

type IAxe = { id?: string; name: string; opposite?: boolean; min?: number; max?: number; visible?: boolean };
type ISerie = {
  id: string;
  name: string;
  type: "line" | "area";
  color: string;
  yAxis?: string;
  lineWidth?: number;
  zIndex?: number;
  tooltip?: TooltipOptions;
  fillColor?: any;
  visible?: boolean;
  fillOpacity?: number;
};

type IChartProps = {
  data: any[];
  yAxes: IAxe[];
  series: ISerie[];
  chartId: string;
};

function getChartOptions(data: any[], yAxis: IAxe[], series: ISerie[], chartId: string): Options {
  return {
    accessibility: {
      enabled: false,
    },
    chart: {
      zoomType: "x",
      type: "line",
      height: "555px",
      animation: false,
    },
    boost: {
      useGPUTranslations: false,
    },
    exporting: {
      enabled: false,
      csv: {
        // decimalPoint: ",",
        // itemDelimiter: "|",
        decimalPoint: ".",
        itemDelimiter: ",",
      },
    },
    time: {
      timezone: "Europe/Ljubljana",
      useUTC: false,
    },
    title: {
      text: undefined,
    },

    credits: {
      enabled: false,
    },

    legend: {
      itemStyle: {
        color: "white",
      },
      itemHoverStyle: {
        color: "#5dbb46",
      },
    },

    tooltip: {
      crosshairs: [true, false],
      shared: true,
      valueDecimals: 0,
      backgroundColor: "rgba(27, 35, 55, 0.85)",
      style: {
        color: "white",
      },
    },

    xAxis: {
      type: "datetime",
      labels: {
        style: {
          color: "white",
        },
      },
    },
    yAxis: yAxis.map((ax) => ({
      id: ax.id,
      title: {
        text: ax.name,
        style: {
          color: "white",
        },
      },
      gridLineColor: ax.opposite ? "transparent" : "slategrey",
      gridLineWidth: 0.5,
      labels: {
        style: {
          color: "white",
        },
      },
      opposite: ax.opposite,
      startOnTick: false,
      endOnTick: false,
      min: ax.min,
      max: ax.max,
      alignTicks: ax.opposite ? true : undefined,
      visible: ax.visible ?? true,
    })),
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
      area: {
        marker: {
          enabled: false,
        },
      },
      series: {
        animation: false,
        boostThreshold: 5000,
        states: {
          hover: {
            enabled: false,
          },
        },
        events: {
          hide: function () {
            PermanentUserPreferences.set(chartId, `${this.userOptions.id}.visible`, false);
          },
          show: function () {
            PermanentUserPreferences.set(chartId, `${this.userOptions.id}.visible`, true);
          },
        },
      },
    },
    series: series.map((s) => ({
      id: s.id,
      name: s.name,
      type: s.type,
      color: s.color,
      lineWidth: s.lineWidth,
      zIndex: s.zIndex,
      tooltip: s.tooltip,
      data: data.map((d) => [d.timestamp, d[s.id]]),
      yAxis: s.yAxis,
      fillColor: s.fillColor,
      visible: PermanentUserPreferences.get(chartId, `${s.id}.visible`, s.visible),
      fillOpacity: s.fillOpacity,
    })),
  } as Options;
}

function _Chart(props: IChartProps, ref: any) {
  return (
    <HighchartsReact ref={ref} highcharts={Highcharts} options={getChartOptions(props.data, props.yAxes, props.series, props.chartId)} />
  );
}

export const Chart = forwardRef<any, IChartProps>(_Chart);
