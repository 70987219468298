import colors from "tailwindcss/colors";
import HistoryToolbar from "../HistoryToolbar";
import Icon from "@mdi/react";
import moment from "moment";
import { Chart } from "../../../../../../components/modules/chart/Chart";
import { getDeviceCustomerAndMeasurementPoint } from "../../../../../deviceList/_common/utils";
import type { IDevice, IDevicePlantType } from "../../../../../../types/Device";
import { type IEnergySummary, RawHistoryStats } from "../historySummaryStats/RawHistoryStats";
import { inverterStateMapping } from "../../states/ngenStar/inverterState/InverterState";
import { LoadingIcon } from "../../../../../../decorators/loading/Loading";
import { mdiClockOutline, mdiDownload } from "@mdi/js";
import { RawHistoryPercentage } from "../historySummaryPercentage/RawHistoryPercentage";
import { RestEnv } from "../../../../../../services/restService/restUtils";
import { useCallback, useEffect, useId, useRef, useState } from "react";
import { useGetData } from "../../../../../../hooks/useGetData";
import { usePeakShaving } from "../usePeakShaving/usePeakShaving";
import { useMqttProperty } from "../../../../../../hooks/useMqttClient";

const getPlantTypeTitle = (plantType: IDevicePlantType | undefined): string => {
  switch (plantType) {
    case "biomass":
      return "Biomass";
    case "hydroelectric":
      return "Hydroelectric";
    case "wind":
      return "Wind";
    default:
      return "Solar";
  }
};

type IState = {
  importLimit: number | null;
  exportLimit: number | null;
};

export default function HistoryRaw(props: { device: IDevice }) {
  const componentId = useId();
  const [state, setState] = useState<IState>({ importLimit: null, exportLimit: null });
  const chartRef = useRef<any>();
  const peakShaveLimiter = usePeakShaving({ chartRef });
  const [date, setDate] = useState<undefined | { year: number; month: number; day: number }>(undefined);
  const { name, mp } = getDeviceCustomerAndMeasurementPoint(props.device);

  const { data, isLoading, fetchData } = useGetData<{ data: any[]; date: string; energySummary: IEnergySummary; impact: any }>({
    request: {
      env: RestEnv.DATA,
      path: `/api/1.0/history/${props.device.device.type}/${props.device.device.id}/daily/${date?.year}/${date?.month}/${date?.day}`,
    },
    mapData: useCallback(
      (data: {
        data_timezone: string;
        date: string;
        summary: {
          energy: IEnergySummary;
          impact: any;
        };
        history_data_raw: {
          [key: number]: {
            battery: number;
            battery_available_power_charge: number;
            battery_available_power_discharge: number;
            battery_temp: number;
            grid: number;
            inverter_state: number;
            load: number;
            ngenstar_remote_control_mode: number;
            ngenstar_remote_control_power: number;
            photovoltaic: number;
            self_sufficiency: number;
            smartgrid_decrease_consumption_relay_state: number;
            smartgrid_increase_consumption_relay_state: number;
            soc: number;
          };
        };
      }) => {
        const [year, month, day] = data.date.substring(0, data.date.indexOf("T")).split("-");

        const startTs = moment.tz(new Date(Number(year), Number(month) - 1, Number(day), 0, 0, 0, 0), "Europe/Ljubljana").unix();

        const newData = Object.entries(data.history_data_raw)
          .map(([minIndex, minValue]) => {
            return {
              ...minValue,
              timestamp: (startTs + Number(minIndex) * 60) * 1000,
            };
          })
          .sort((a, b) => a.timestamp - b.timestamp);

        return {
          data: newData,
          date: moment.tz().format("DD.MM.YYYY HH:mm:ss"),
          energySummary: data.summary.energy,
          impact: data.summary.impact,
        };
      },
      [],
    ),
    skipFetch: !date,
  });

  useMqttProperty("site", componentId, 60_000, (data: { import_limit: number; export_limit: number }) => {
    setState({ importLimit: data.import_limit, exportLimit: data.export_limit * -1 });
  });

  useEffect(() => {
    if (props.device.device.type !== "ngenstar") {
      peakShaveLimiter.hideImportLimits();
      peakShaveLimiter.hideExportLimits();
    } else if (state.importLimit !== null && state.exportLimit !== null) {
      peakShaveLimiter.showImportLimits({ importLimit: state.importLimit });
      peakShaveLimiter.showExportLimits({ exportLimit: state.exportLimit });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.device.device.type, state.importLimit, state.exportLimit, isLoading]);

  const onNewDate = useCallback((newDateObj: { year: number; month: number; day: number }) => {
    setDate(newDateObj);
  }, []);

  const refreshData = () => {
    fetchData();
  };

  return (
    <>
      <HistoryToolbar type="day" key={"historyToolbar"} onNewDate={onNewDate} refreshData={refreshData} />

      {isLoading ? (
        <div style={{ height: "555px" }} className="flex h-full w-full items-center justify-center">
          <LoadingIcon isBig bounce />
        </div>
      ) : (
        <>
          <div className="relative rounded bg-brand-950">
            <Chart
              chartId="rawHistoryChart"
              ref={chartRef}
              data={data?.data || []}
              yAxes={[
                { name: "Power (W)" },
                { name: "Inverter state", id: "inverterStateAxe", opposite: true, min: 0, max: 10, visible: false },
                { name: "SOC (%)", id: "soceAxe", opposite: true, min: 0, max: 100, visible: false },
                { name: "Temperature (°C)", id: "temperatreAxe", opposite: true, min: 0, max: 100, visible: false },
              ]}
              series={[
                {
                  id: "battery",
                  name: "Battery power",
                  type: "line",
                  color: colors.green[400],
                  lineWidth: 2,
                  zIndex: 4,
                  tooltip: {
                    valueSuffix: "W",
                  },
                },
                {
                  id: "load",
                  name: "Site power",
                  type: "line",
                  color: colors.blue[400],
                  lineWidth: 2,
                  zIndex: 4,
                  tooltip: {
                    valueSuffix: "W",
                  },
                },
                {
                  id: "grid",
                  name: "Grid power",
                  type: "line",
                  color: colors.gray[400],
                  lineWidth: 2,
                  zIndex: 4,
                  tooltip: {
                    valueSuffix: "W",
                  },
                },
                {
                  id: "photovoltaic",
                  name: `${getPlantTypeTitle(props.device.device.power_plant_type)} power`,
                  type: "line",
                  color: colors.yellow[400],
                  lineWidth: 2,
                  zIndex: 1,
                  tooltip: {
                    valueSuffix: "W",
                  },
                },
                {
                  id: "battery_available_power_discharge",
                  name: "Max discharge power",
                  type: "area",
                  color: "#3477aa",
                  fillColor: {
                    linearGradient: {
                      x1: 0,
                      y1: 0,
                      x2: 0,
                      y2: 0.95,
                    },
                    stops: [
                      [0, "rgba(0, 89, 255, 0.2)"],
                      [1, "rgba(0, 89, 255, 0.05)"],
                    ],
                  },
                  lineWidth: 1,
                  zIndex: 3,
                  fillOpacity: 0.95,
                  tooltip: {
                    valueSuffix: "W",
                  },
                },
                {
                  id: "battery_available_power_charge",
                  name: "Max charge power",
                  type: "area",
                  color: "#cc3e44",
                  fillColor: {
                    linearGradient: {
                      x1: 0,
                      y1: 0.95,
                      x2: 0,
                      y2: 0,
                    },
                    stops: [
                      [0, "rgba(255, 0, 0, 0.2)"],
                      [1, "rgba(255, 0, 0, 0.05)"],
                    ],
                  },
                  lineWidth: 1,
                  zIndex: 3,
                  fillOpacity: 0.95,
                  tooltip: {
                    valueSuffix: "W",
                  },
                },
                {
                  id: "battery_temp",
                  name: "Battery temperature",
                  type: "line",
                  color: colors.red[400],
                  lineWidth: 2,
                  zIndex: 4,
                  yAxis: "temperatreAxe",
                  visible: true,
                  tooltip: {
                    valueSuffix: "°C",
                  },
                },
                {
                  id: "soc",
                  name: "Soc",
                  type: "area",
                  color: "#5fba46",
                  fillColor: {
                    linearGradient: {
                      x1: 0,
                      y1: 0,
                      x2: 0,
                      y2: 0.95,
                    },
                    stops: [
                      [0, "rgba(102, 255, 0, 0.2)"],
                      [1, "rgba(102, 255, 0, 0.05)"],
                    ],
                  },
                  fillOpacity: 0.95,
                  lineWidth: 2,
                  zIndex: 0,
                  yAxis: "soceAxe",
                  tooltip: {
                    valueSuffix: "%",
                  },
                  visible: true,
                },
                {
                  id: "inverter_state",
                  name: "Inverters state",
                  type: "line",
                  color: colors.orange[400],
                  lineWidth: 2,
                  zIndex: 4,
                  yAxis: "inverterStateAxe",
                  visible: true,
                  tooltip: {
                    pointFormatter: function () {
                      const _this = this as any;
                      return "Inverter state: " + (inverterStateMapping[_this.y as any]?.toUpperCase() || _this.y);
                    },
                  },
                },
              ]}
            />
            {data?.date != null && (
              <div className="absolute right-2 top-2 flex items-center gap-1 text-right font-mono text-xs font-extralight italic text-brand-500">
                <Icon path={mdiClockOutline} className="h-3 w-3" />
                {data?.date}
              </div>
            )}
          </div>
          <button
            className="flex items-center justify-center gap-1 rounded border border-brand-200 bg-brand-100 px-4 py-2 font-bold  shadow-sm hover:bg-brand-200 dark:border-brand-800 dark:bg-brand-900  dark:hover:bg-brand-800"
            onClick={() => {
              chartRef.current.chart.downloadCSV?.(`${name}_${mp}_${date?.year}-${date?.month}-${date?.day}`);
            }}
          >
            <Icon path={mdiDownload} className="-mb-1 h-4 w-4" />
            Download .csv
          </button>
          <RawHistoryStats energySummary={data?.energySummary} devicePlantType={props.device.device.power_plant_type} />
          <RawHistoryPercentage impact={data?.impact} />
        </>
      )}
    </>
  );
}
