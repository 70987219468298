import Highcharts from "highcharts";
import HighchartsReact, { HighchartsReactProps, HighchartsReactRefObject } from "highcharts-react-official";
import { Ref } from "react";

type LegacyChartProps = {
  options: Highcharts.Options;
  chartRef: Ref<HighchartsReactRefObject>;
} & HighchartsReactProps;

export const LegacyChart = ({ options, chartRef, ...props }: LegacyChartProps): React.JSX.Element => {
  return <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} {...props} />;
};
