import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";
import { MQTTService } from "../../services/mqttService/mqttService";
import { useUser } from "../userRestrictedArea/UserRestrictedArea";

export enum ConnectionStates {
  Connecting = "Connecting",
  Connected = "Connected",
  Closed = "Closed",
}

const MQTTConnectionContext = createContext<{
  ngenstarService: MQTTService;
  ngenstarState: ConnectionStates;
  powerbankService: MQTTService;
  powerbankState: ConnectionStates;
}>({
  ngenstarService: new MQTTService(),
  ngenstarState: ConnectionStates.Connecting,
  powerbankService: new MQTTService(),
  powerbankState: ConnectionStates.Connecting,
});

export const MQTTConnectionProvider = ({ children }: { children: ReactNode }) => {
  const user = useUser();
  const ngenstarService = useMemo(() => new MQTTService(), []);
  const powerbankService = useMemo(() => new MQTTService(), []);

  const [ngenstarState, setNgenstarState] = useState<ConnectionStates>(ConnectionStates.Connecting);
  const [powerbankState, setPowerbankState] = useState<ConnectionStates>(ConnectionStates.Connecting);

  useEffect(() => {
    if (!ngenstarService.client) {
      setNgenstarState(ConnectionStates.Connecting);
      const ngenstarClient = ngenstarService.connect("ngenstar", user.user?.token || "", "1111111111");

      ngenstarClient.on("connect", () => setNgenstarState(ConnectionStates.Connected));
      ngenstarClient.on("offline", () => setNgenstarState(ConnectionStates.Closed));
      ngenstarClient.on("disconnect", () => setNgenstarState(ConnectionStates.Closed));
      ngenstarClient.on("close", () => setNgenstarState(ConnectionStates.Closed));
      ngenstarClient.on("reconnect", () => setNgenstarState(ConnectionStates.Connecting));
    }
  }, [user, ngenstarService]);

  useEffect(() => {
    if (!powerbankService.client) {
      setPowerbankState(ConnectionStates.Connecting);
      const ngenstarClient = powerbankService.connect("powerbank", user.user?.token || "", "1111111111");

      ngenstarClient.on("connect", () => setPowerbankState(ConnectionStates.Connected));
      ngenstarClient.on("offline", () => setPowerbankState(ConnectionStates.Closed));
      ngenstarClient.on("disconnect", () => setPowerbankState(ConnectionStates.Closed));
      ngenstarClient.on("close", () => setPowerbankState(ConnectionStates.Closed));
      ngenstarClient.on("reconnect", () => setPowerbankState(ConnectionStates.Connecting));
    }
  }, [user, powerbankService]);

  return (
    <MQTTConnectionContext.Provider value={{ ngenstarService, ngenstarState, powerbankService, powerbankState }}>
      {children}
    </MQTTConnectionContext.Provider>
  );
};

export const useMqttConnection = (): {
  ngenstarService: MQTTService;
  ngenstarState: ConnectionStates;
  powerbankService: MQTTService;
  powerbankState: ConnectionStates;
} => {
  return useContext(MQTTConnectionContext);
};
