import Icon from "@mdi/react";
import { mdiLoading } from "@mdi/js";

export function Loading(props: { text?: string; className?: string; iconPosition?: "left" | "top"; hideShadow?: boolean }) {
  const showAnimation = true;

  if (props.iconPosition === "top") {
    return (
      <div className={"w-full rounded-lg p-4 text-sm " + (props.hideShadow ? "" : "shadow")}>
        <div className={"flex w-full flex-col items-center gap-2 " + (props.className ?? "")}>
          {showAnimation && <LoadingIcon className="text-xl" bounce isBig />}
          <div>{props.text ?? "Loading..."}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={"w-full rounded-lg p-4 text-sm " + (props.hideShadow ? "" : "shadow")}>
      <div className={"flex w-full items-center gap-2 " + (props.className ?? "")}>
        {showAnimation && <LoadingIcon />}
        <div>{props.text ?? "Loading..."}</div>
      </div>
    </div>
  );
}

export function LoadingIcon(props: { className?: string; bounce?: boolean; isBig?: boolean }) {
  return (
    <div className={"-mb-0.5 rounded-full border border-brand-500/50 " + props.className + (props.bounce ? " animate-bounce " : " ")}>
      <Icon path={mdiLoading} className={" animate-spin " + (props.isBig ? "h-6 w-6" : "h-3 w-3")} />
    </div>
  );
}
