import Animation from "./animation/Animation";
import AnimationHydro from "./animation/AnimationHydro";
import AnimationPowerPlant from "./animation/AnimationPowerPlant";
import AnimationSolar from "./animation/AnimationSolar";
import AnimationWind from "./animation/AnimationWind";
import Container from "../../../../decorators/container/Container";
import { BatteryData, EnergyData, SmartGridStruct, SmartMeter } from "./animation/types/AnimationsTypes";
import { IDevice } from "../../../../types/Device";
import { mdiTabletCellphone } from "@mdi/js";
import { useId, useState } from "react";
import { useMqttProperty } from "../../../../hooks/useMqttClient";

export default function EnergyScheme(props: { device: IDevice }) {
  const componentId = useId();
  const [state, setState] = useState<{
    inverterState: number;
    status: string;
  }>({
    inverterState: 2,
    status: "unknown",
  });

  useMqttProperty(
    "status",
    componentId,
    2_000,
    (data: {
      status: string;
      metadata: {
        utc_timestamp: number;
      };
    }) => {
      setState((prevState) => ({
        ...prevState,
        status: data.status,
      }));
    },
  );

  return (
    <div className="w-full lg:max-w-md ">
      <Container title="NGEN App view" icon={mdiTabletCellphone} className="sticky top-20">
        <div className="rounded-lg bg-brand-950 py-2 shadow-lg">
          <div className={state.status}>
            <AnimationBasedOnDeviceType device={props.device} />
          </div>
        </div>
      </Container>
    </div>
  );
}

function AnimationBasedOnDeviceType(props: { device: IDevice }) {
  switch (props.device.device.type) {
    case "smart-meter":
      return <SmartMeterAnimation device={props.device} />;
    case "powerbank":
      return <PowerbankAnimation device={props.device} />;
    case "ngenstar":
      return <GeneralAnimation device={props.device} />;
    default:
      return <>Unfortunately the device type is not supported: {props.device.device.type}</>;
  }
}

function SmartMeterAnimation(props: { device: IDevice }) {
  const componentId = useId();

  const [state, setState] = useState<SmartMeter>({
    import_power: 0,
    export_power: 0,
    power: null,
    metadata: {
      utc_timestamp: 0,
    },
  });

  useMqttProperty("meter", componentId, 2_000, (data: { import_power: number; export_power: number; power: number }) => {
    setState({
      import_power: data.import_power,
      export_power: data.export_power,
      power: data.power,
      metadata: {
        utc_timestamp: Date.now(),
      },
    });
  });

  switch (props.device.device.power_plant_type) {
    case "hydroelectric":
      return <AnimationHydro smartMeter={state} />;
    case "solar":
      return <AnimationSolar smartMeter={state} />;
    case "wind":
      return <AnimationWind smartMeter={state} />;
    case "none":
      return <GeneralAnimation device={props.device} />;
    default:
      return <>Unfortunately the power plant type is not supported: {props.device.device.power_plant_type}</>;
  }
}

function GeneralAnimation(props: { device: IDevice }) {
  const componentId = useId();
  const [state, setState] = useState<{
    data: EnergyData;
    batteryStats: BatteryData;
    smartMeter: SmartMeter;
    smartgrid: SmartGridStruct;
    inverterState: number;
    status: string;
  }>({
    data: {
      photovoltaic: 0,
      battery: 0,
      // @ts-ignore because of legacy code
      grid: null,
      load: 0,
      inverter: 0,
      load_calculated: 0,
      gui: {
        photovoltaic: 0,
        battery: 0,
        grid: 0,
        load: 0,
      },
      metadata: {
        utc_timestamp: 0,
      },
    },
    batteryStats: {
      soc: 0,
      power: 0,
      temperature: 0,
      "available-battery-power-charge": 0,
      "available-battery-power-discharge": 0,
      metadata: {
        utc_timestamp: 0,
      },
    },
    smartMeter: {
      import_power: 0,
      export_power: 0,
      power: 0,
      metadata: {
        utc_timestamp: 0,
      },
    },
    smartgrid: {
      enabled: null,
      metadata: {
        utc_timestamp: 0,
      },
    },
    inverterState: 2,
    status: "unknown",
  });

  useMqttProperty("battery", componentId, 2_000, (data) => {
    setState((prevState) => ({
      ...prevState,
      batteryStats: data,
    }));
  });

  useMqttProperty("smartgrid", componentId, 2_000, (data) => {
    setState((prevState) => ({
      ...prevState,
      smartgrid: data,
    }));
  });

  useMqttProperty("inverter", componentId, 2_000, (data) => {
    setState((prevState) => ({
      ...prevState,
      inverterState: data.state,
    }));
  });

  useMqttProperty("power", componentId, 2_000, (data) => {
    setState((prevState) => ({
      ...prevState,
      data: data,
    }));
  });

  useMqttProperty("meter", componentId, 2_000, (data) => {
    setState((prevState) => ({
      ...prevState,
      smartMeter: data,
    }));
  });

  useMqttProperty(
    "status",
    componentId,
    2_000,
    (data: {
      status: string;
      metadata: {
        utc_timestamp: number;
      };
    }) => {
      setState((prevState) => ({
        ...prevState,
        status: data.status,
      }));
    },
  );

  return (
    <Animation
      data={state.data}
      batteryStats={state.batteryStats}
      smartMeter={state.smartMeter}
      deviceType={props.device.device.type}
      firstMessageReceived={true}
      smartgrid={state.smartgrid.enabled}
      inverterState={state.inverterState}
      smartImport={state.smartgrid}
    />
  );
}

function PowerbankAnimation(props: { device: IDevice }) {
  const componentId = useId();

  const [state, setState] = useState<{
    pbBattery: any;
    pbMeter: any;
    pbSolar: any;
  }>({
    pbMeter: {
      ac_w_tot: 0.0,
      alarms: 0,
      batt_soc_avg: 0,
      build_ac_w: 0,
      grid_cap_w_tot: null,
      meter_ac_w: null,
      srv_type: 0,
      sys_status: 0,
      warnings: 0,
    },
    pbBattery: {
      batt_a: 0,
      batt_cap_kwh: 0,
      batt_cap_w_ch: 0,
      batt_cap_w_disch: 0,
      batt_soc: 0,
      batt_soc_max: 0,
      batt_soc_min: 0,
      batt_soh: 0,
      batt_status: 0,
      batt_temp_max: 0,
      batt_temp_min: 0,
      batt_v: 0,
      batt_w: 0,
      m_ts: "2023-04-17T20:18:28Z",
      num_batt: 0,
    },
    pbSolar: {
      timestamp: 1695275210,
      gateway: {
        name: "AXM-WEB2",
        model: "AXM-WEB2",
        serial: "AN53070180",
      },
      device: {
        name: "SOLAR_METER",
        model: "Acuvim-II",
        serial: "XXX000000",
        online: true,
        readings: [
          {
            param: "P1",
            value: "0.479",
            unit: "kW",
          },
          {
            param: "P2",
            value: "0.512",
            unit: "kW",
          },
          {
            param: "P3",
            value: "0.420",
            unit: "kW",
          },
          {
            param: "Psum_kW",
            value: 0,
            unit: "kW",
          },
        ],
      },
    },
  });

  useMqttProperty("status/solar", componentId, 0, (data: any) => {
    setState((prevState) => ({
      ...prevState,
      pbSolar: data,
    }));
  });

  useMqttProperty("status/op", componentId, 0, (data: any) => {
    setState((prevState) => ({
      ...prevState,
      pbMeter: data,
    }));
  });

  useMqttProperty("battery", componentId, 0, (data: any) => {
    setState((prevState) => ({
      ...prevState,
      pbBattery: data,
    }));
  });

  return <AnimationPowerPlant pbBattery={state.pbBattery} pbMeter={state.pbMeter} pbSolar={state.pbSolar} />;
}
