import Icon from "@mdi/react";
import { ChangeGroupConfig } from "./config/ChargeGroupConfig";
import { IChargeGroup } from "../../../types/ChargeGroup";
import { mdiCurrencyUsd, mdiCurrencyUsdOff, mdiLatitude, mdiLockOpenVariantOutline, mdiLockOutline, mdiLongitude } from "@mdi/js";
import { Value } from "../../device/deviceLayout/customerData/_shared/Value";

export function ChangeGroupMeta(props: { chargeGroup: IChargeGroup }) {
  return (
    <div className="flex flex-col gap-8">
      <div className={`break-words`}>
        <div className="text-xl font-semibold">{props.chargeGroup.name}</div>
        <div className="mb-4 text-sm text-brand-600 dark:text-brand-500">{props.chargeGroup.description}</div>

        <div className="flex flex-col gap-2">
          <Value label="Tenant" value={props.chargeGroup.tenant || "N/A"} />
          <Value label="Address" value={props.chargeGroup.address || "N/A"} />
          <Value
            label="City"
            value={[props.chargeGroup.zip_code || null, props.chargeGroup.city || null].filter((t) => !!t).join(" ") || "N/A"}
          />

          <Value
            label="Payment"
            value={
              <div className="flex h-full items-center gap-1">
                <Icon
                  path={props.chargeGroup.freecharge ? mdiCurrencyUsdOff : mdiCurrencyUsd}
                  className={"h-4 w-4 " + (props.chargeGroup.freecharge ? "text-green-500" : "text-blue-500")}
                />
                {props.chargeGroup.freecharge ? "Free" : "Paid"}
              </div>
            }
          />

          <Value
            label="Visibility"
            value={
              <div className="flex h-full items-center gap-1">
                <Icon
                  path={props.chargeGroup.group_type === "PUB" ? mdiLockOpenVariantOutline : mdiLockOutline}
                  className={"h-4 w-4 " + (props.chargeGroup.group_type === "PUB" ? "text-green-500" : "text-blue-500")}
                />
                {props.chargeGroup.group_type === "PUB" ? "Public" : "Private"}
              </div>
            }
          />

          <Value
            label="Coordinates"
            value={
              <>
                <div className="flex items-center gap-1 text-sm leading-tight">
                  <Icon path={mdiLongitude} className="inline size-3" />
                  <span className="tabular-nums">{props.chargeGroup.longitude}</span>
                </div>
                <div className="flex items-center gap-1 text-sm leading-tight">
                  <Icon path={mdiLatitude} className="inline size-3" />
                  <span className="tabular-nums">{props.chargeGroup.latitude}</span>
                </div>
              </>
            }
          />
        </div>
      </div>

      <ChangeGroupConfig chargeGroup={props.chargeGroup} />
    </div>
  );
}
