import Icon from "@mdi/react";
import { DeviceStatus } from "./_common/DeviceStatus";
import { getDeviceCustomerAndMeasurementPoint } from "./_common/utils";
import { getDeviceTypeDefinition } from "./deviceListContent/DeviceListContent.utils";
import { IDevice } from "../../types/Device";
import { Link } from "react-router-dom";
import { mdiOpenInApp, mdiOpenInNew } from "@mdi/js";
import { useInView } from "react-intersection-observer";
import { useUser } from "../../layout/userRestrictedArea/UserRestrictedArea";

export default function DeviceListTable({ data }: { data: IDevice[] }) {
  const { user } = useUser();
  const isSuperAdmin = user?.permissions.isSuperAdmin || false;

  return (
    <div className="relative w-full overflow-x-auto rounded-md border border-transparent shadow dark:border-brand-900">
      <table className="w-full text-left text-sm">
        <thead className="border-b-2 border-brand-100 bg-brand-50 text-xs uppercase dark:border-b dark:border-brand-800 dark:bg-brand-900">
          <tr>
            <th className="w-4/12 px-6 py-3">Customer</th>

            <th className="w-1/12 px-6 py-3">Device IP</th>
            <th className="w-1/12 px-6 py-3">Version</th>
            <th className="w-2/12 px-6 py-3">Device type</th>
            <th className="w-3/12 px-6 py-3">Device status</th>

            <th className="w-1/12 px-6 py-3">
              <span className="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((d, i, a) => (
            <Row d={d} key={i} isSuperAdmin={isSuperAdmin} showBottomBorder={i < a.length - 1} />
          ))}

          {data.length === 0 && (
            <tr>
              <td colSpan={6} className="px-6 py-3 text-center">
                No devices found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

function Row({ d, isSuperAdmin, showBottomBorder }: { d: IDevice; isSuperAdmin?: boolean; showBottomBorder?: boolean }) {
  const { ref, inView } = useInView({
    delay: 100,
  });
  const { name, mp } = getDeviceCustomerAndMeasurementPoint(d);
  const devDef = getDeviceTypeDefinition(d);
  return (
    <tr
      key={d.device.id}
      className={
        "hover:bg-brand-100 dark:border-brand-900 hover:dark:bg-brand-900 " + (showBottomBorder ? "border-b border-brand-100 " : "")
      }
      ref={ref}
    >
      {!inView ? (
        <td className="block min-h-[50px] px-6 py-3" />
      ) : (
        <>
          <td className="block min-h-[50px] px-6 py-3">
            <div>{name}</div>

            <div className="flex gap-1 text-xs text-brand-500">
              <span>Serial no.: {d.meter_serial_number}</span>
              <span> | </span>
              <span>Point: {mp}</span>
            </div>
          </td>

          <td className="px-6 py-3">{d.device.ip}</td>
          <td className="px-6 py-3">{d.software_version}</td>

          <td className="px-6 py-3">
            <div className="flex h-full items-center gap-1">
              {devDef.icon && <Icon path={devDef.icon} className={"-mb-0.5 h-4 w-4 " + devDef.classNameColor} />}
              {devDef.name}
            </div>
          </td>
          <td className="flex flex-col px-6 py-3">
            <div>
              <DeviceStatus status={d.online} />
            </div>

            <span className="text-xs text-brand-500">Last online: {d.last_online}</span>
          </td>
          <td className=" px-6 py-3 text-right">
            <div className="flex items-center justify-center gap-4">
              {isSuperAdmin && d.measuring_point.odoo_id != null && (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to={`http://ps.ngen.si/web#id=${d.measuring_point.odoo_id}&action=999&model=measuring_points.measuring_points_model&view_type=form&cids=1&menu_id=694`}
                  className="flex items-center gap-1 font-medium text-ngen hover:cursor-pointer hover:underline"
                >
                  <Icon path={mdiOpenInNew} className="-mb-0.5 h-4 w-4" />
                  Odoo
                </Link>
              )}

              <Link
                to={"/device/" + d.device.id}
                className="flex items-center gap-1 font-medium text-ngen hover:cursor-pointer hover:underline"
              >
                <Icon path={mdiOpenInApp} className="-mb-0.5 h-4 w-4" />
                View
              </Link>
            </div>
          </td>
        </>
      )}
    </tr>
  );
}
