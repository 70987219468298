import Icon from "@mdi/react";
import { clsx } from "../../../utils/clsx";
import { ConnectionStates, useMqttConnection } from "../../mqttConnectionProvider/MQTTConnectionProvider";
import { Fragment } from "react";
import { mdiAccountOutline, mdiLogout } from "@mdi/js";
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import { NTooltip } from "../../../views/device/_common/StatusValue";
import { useUser } from "../../userRestrictedArea/UserRestrictedArea";

const StateTextColors = {
  [ConnectionStates.Connecting]: "text-yellow-500",
  [ConnectionStates.Connected]: "text-ngen",
  [ConnectionStates.Closed]: "text-red-500",
};

function getServiceStateColors(ngenstarState: ConnectionStates, powerbankState: ConnectionStates) {
  if (ngenstarState === ConnectionStates.Connecting && powerbankState === ConnectionStates.Connecting) {
    return "bg-yellow-500";
  }

  if (ngenstarState === ConnectionStates.Connected && powerbankState === ConnectionStates.Connected) {
    return "bg-ngen";
  }

  if (ngenstarState === ConnectionStates.Closed && powerbankState === ConnectionStates.Closed) {
    return "bg-red-500";
  }

  return "bg-orange-500";
}

export default function UserSettings() {
  const { logUserOut, user } = useUser();
  const { ngenstarState, powerbankState } = useMqttConnection();

  const allStateBgColor = getServiceStateColors(ngenstarState, powerbankState);

  return (
    <Menu>
      <MenuButton>
        <div className="text-shadow flex items-center gap-2 hover:cursor-pointer">
          <div className="hidden flex-col items-end sm:flex">
            <div className="truncate text-sm" style={{ maxWidth: "20ch" }}>
              {user?.name || "Unknown user name"}
            </div>
            <div className="-mt-1 text-xs text-brand-700 dark:text-brand-500">{user?.email || "Unknown email"}</div>
          </div>
          <div className="relative ml-1 h-7">
            <Icon path={mdiAccountOutline} className="h-7 w-7 rounded-full bg-brand-200 p-1 shadow dark:bg-brand-800" />
            <span className="absolute -right-1 -top-1 flex size-2.5">
              <span className={clsx("absolute top-2 inline-flex h-full w-full animate-ping rounded-full opacity-75", allStateBgColor)} />
              <NTooltip
                text={
                  <div className="flex w-full flex-col text-right">
                    <div>
                      NGENSTAR connection status: <span className={StateTextColors[ngenstarState]}>{ngenstarState}</span>
                    </div>
                    <div>
                      POWERBANK connection status: <span className={StateTextColors[powerbankState]}>{powerbankState}</span>
                    </div>
                  </div>
                }
                position="right"
              >
                <span className={clsx("relative inline-flex size-2.5 rounded-full ", allStateBgColor)}></span>
              </NTooltip>
            </span>
          </div>
        </div>
      </MenuButton>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-75"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <MenuItems
          modal={false}
          anchor="bottom end"
          className="z-50 mt-1 w-40 origin-top-right rounded-md border border-brand-100 bg-brand-100 p-1 text-brand-900 shadow-md focus:outline-none dark:border-brand-800 dark:bg-brand-900 dark:text-brand-100"
        >
          <MenuItem>
            <button
              onClick={logUserOut}
              className="group flex w-full items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-brand-200 dark:data-[focus]:bg-brand-800"
            >
              <Icon path={mdiLogout} className="size-4" />
              Log out
            </button>
          </MenuItem>
        </MenuItems>
      </Transition>
    </Menu>
  );
}
