import Icon from "@mdi/react";
import { EntitySelector } from "./EntitySelector/EntitySelector";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { mdiMagnify } from "@mdi/js";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../userRestrictedArea/UserRestrictedArea";

export default function Searchbar() {
  const searchRef = useRef<ISearchInputActions>(null);
  const user = useUser();

  return (
    <div className="-my-1 flex w-full max-w-xs content-center rounded-md border-b border-brand-200 bg-brand-50 focus-within:border-ngen dark:border-brand-700 dark:bg-brand-800 dark:focus-within:border-ngen lg:max-w-screen-sm xl:max-w-screen-md">
      {user.user?.permissions?.isSuperAdmin && <EntitySelector />}
      <SearchInput ref={searchRef} />
      <div
        className="flex items-center px-1 hover:cursor-pointer hover:text-ngen "
        onClick={() => {
          searchRef.current?.searchForText();
        }}
      >
        <Icon path={mdiMagnify} className="mx-3 h-5 w-5" />
      </div>
    </div>
  );
}

type ISearchInputActions = { searchForText: () => void };
const SearchInput = forwardRef<ISearchInputActions>((props: any, ref: any) => {
  useImperativeHandle(ref, () => ({
    searchForText,
  }));

  const location = useLocation();
  const navigate = useNavigate();
  const [text, setText] = useState<string>("");

  useEffect(() => {
    if (location.pathname === "/") {
      setText("");
    } else if (location.search) {
      // if search query exists, set text
      const params = new URLSearchParams(location.search);
      const query = params.get("query");
      setText(query || "");
    } else {
      // if no search query, clear text
      setText("");
    }
  }, [location]);

  const searchForText = () => {
    let destination = location.pathname;

    if (destination.startsWith("/device/")) {
      destination = "/device";
    } else if (destination.startsWith("/chargeGroup/")) {
      destination = "/chargeGroup";
    }

    let newPath = destination + "?";
    newPath += new URLSearchParams({
      query: text,
    }).toString();

    navigate(newPath, { replace: true });
  };

  return (
    <input
      type="search"
      value={text}
      onChange={(ev) => setText(ev.target.value)}
      className="w-full border-none bg-transparent placeholder:italic focus:border-none focus:ring-0 placeholder:dark:text-brand-500"
      placeholder="Search ..."
      onKeyDown={(evt) => {
        if (evt.key === "Enter") {
          searchForText();
        }
      }}
    />
  );
});
