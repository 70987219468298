import Container from "../../../decorators/container/Container";
import CustomerData from "./customerData/CustomerData";
import DeviceDetails from "./deviceDetails/DeviceDetails";
import EnergyScheme from "./energyScheme/EnergyScheme";
import { IDevice } from "../../../types/Device";
import { Loading } from "../../../decorators/loading/Loading";
import { ReactNode, useEffect, useRef } from "react";

export default function DeviceLayout({ device, connected }: { device: IDevice; connected: boolean }) {
  return (
    <div className="flex w-full flex-col lg:flex-row">
      <div className="w-full border-brand-100 px-8 py-4 dark:border-brand-900 lg:max-w-xs lg:border-r-2 lg:dark:border-r">
        <CustomerData device={device} />
      </div>
      <div className="w-full px-8 py-4">
        {connected ? (
          <div className="flex flex-col-reverse gap-8 lg:flex-row">
            <DeviceDetails device={device} />
            <EnergyScheme device={device} />
          </div>
        ) : (
          <ScrollIntoView>
            <Container title="Connecting to device ...">
              <Loading text="Connecting to device, please wait ..." iconPosition="top" />
            </Container>
          </ScrollIntoView>
        )}
      </div>
    </div>
  );
}

function ScrollIntoView(props: { children: ReactNode }) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div ref={ref} style={{ scrollMarginTop: "100px" }}>
      {props.children}
    </div>
  );
}
