import { PropsWithChildren } from "react";

export function InfiniteLoopSlider({
  children,
  duration = 100,
  direction = "normal",
}: PropsWithChildren<{ duration?: number; direction?: "normal" | "reverse" }>): React.JSX.Element {
  return (
    <div className="relative flex w-full flex-shrink-0 flex-col overflow-hidden pb-2">
      <div
        className="absolute h-full w-full"
        style={{
          boxShadow: "20px -10px 20px -4px #222d3f inset, -20px -10px 20px -4px #222d3f inset",
          zIndex: 40,
        }}
      />
      <div className="flex w-fit animate-infinite-scroll" style={{ animationDuration: `${duration}s`, animationDirection: direction }}>
        {children}
        {children}
      </div>
    </div>
  );
}
