import batNgen from "./assets/baterija_ngen.json";
import DotSpinner from "./dotSpinner/DotSpinner";
import hisa from "./assets/toplotna.png";
import hisaNgen from "./assets/hisa_ngen.json";
import industryImg from "./assets/powerbank.png";
import LottieAnimation from "./LottieAnimation";
import ngenBat from "./assets/ngen_baterija.json";
import ngenHisa from "./assets/ngen_hisa.json";
import ngenOmarica from "./assets/ngen_omarica.json";
import nstartNoSC from "./assets/nstarBrezTc.png";
import omaricaNgen from "./assets/omarica_ngen.json";
import smartMeterImg from "./assets/smTreeSG.png";
import solarNgen from "./assets/solar_ngen.json";
import toplotna from "./assets/toplotna.json";
import { AnimationProps } from "./interfaces/AnimationsInterface";
import { getTimeOfDay, TimeOfDay } from "./helpers/SunCalcModule";
import { InverterStateStyles } from "./types/AnimationsTypes";
import { NgenStarProBadge } from "./ngenStarProBadge/NgenStarProBadge";
import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Animation.css";

const Animation: React.FC<AnimationProps> = ({
  data,
  batteryStats,
  smartMeter,
  deviceType,
  firstMessageReceived,
  className,
  smartgrid,
  opacity,
  inverterState,
  smartImport,
}) => {
  // const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [currLang, setCurrLang] = useState<string>(i18n.language);
  const formatPower = (value: any) => {
    const kWValue = Math.abs(value / 1000).toFixed(1);
    return parseFloat(kWValue) % 1 === 0 ? parseFloat(kWValue).toFixed(0) : kWValue;
  };
  const inverterStateStyles: InverterStateStyles = {
    0: {
      fill: "orange",
      transform: "translate(255 1169)",
      title: t("animation.waiting"),
    },
    1: {
      fill: currLang === "sl" ? "orange" : "orange",
      transform: currLang === "sl" ? "translate(300 1169)" : "translate(363 1169)",
      title: t("animation.self-check"),
    },
    3: {
      fill: "#5EB946",
      transform: "translate(255 1169)",
      title: t("animation.emergency-power-supply"),
    },
    4: {
      fill: "#FF3F3F",
      transform: "translate(408 1169)",
      title: t("animation.fault"),
    },
    5: {
      fill: "#FF3F3F",
      transform: "translate(408 1169)",
      title: t("animation.fault"),
    },
    8: {
      fill: "orange",
      transform: "translate(408 1169)",
      title: t("animation.idle"),
    },
  };

  const getFormattedTimestamp = () => {
    let date;
    if (deviceType === "ngenstar" || deviceType === "powerbank") {
      date = new Date(data.metadata.utc_timestamp * 1000);
      date.setHours(date.getHours());
    } else {
      date = new Date();
    }
    return date.toLocaleString("sl-SI");
  };
  const shouldDisplayTimestamp = () => {
    if (deviceType === "smart-meter") {
      return smartMeter.power !== null;
    } else {
      return data.grid !== null;
    }
  };

  const handleClick = () => {
    console.log("Clicked on the group element");
  };
  const energyGrid = () => {
    // navigate("/energy?id=4");
  };
  const energyBattery = () => {
    // navigate("/energy?id=3");
  };
  const energyHome = () => {
    // navigate("/energy?id=1");
  };
  const energySolar = () => {
    // navigate("/energy?id=2");
  };
  const getBatStatus = (batteryVal: number) => {
    if (batteryVal < 0) {
      return { status: t("home.charging"), color: "#5EB946" }; // Corresponds to text-green-550
    } else if (batteryVal > 0) {
      return { status: t("home.discharging"), color: "#38BDF8" }; // Corresponds to text-blue-500
    } else if (batteryVal === 0) {
      return { status: t("home.standby"), color: "#6B7280" }; // Corresponds to text-gray-500
    } else {
      return { status: t("home.data"), color: "#6B7280" }; // Corresponds to text-gray-500
    }
  };
  useEffect(() => {
    setCurrLang(i18n.language);
  }, [i18n.language]);
  const [timeOfDay, setTimeOfDay] = useState<TimeOfDay | null>(null);

  useEffect(() => {
    try {
      const position = { latitude: 46.0569, longitude: 14.5058 }; // Ljubljana
      const time = getTimeOfDay(position);
      setTimeOfDay(time);
    } catch (error) {
      console.error("Error getting time of day:", error);
    }
  }, [smartImport, smartMeter, data]);
  return (
    <div className={"sky py-3 " + (timeOfDay || "")}>
      <div
        id="animationHome"
        className={`relative ${className ? className : ""} animationHome`}
        style={{ height: "auto", opacity: opacity }}
      >
        {data.gui != null && (
          <>
            {data.gui.photovoltaic > 0 && deviceType === "ngenstar" && firstMessageReceived && (
              <LottieAnimation id="solarNgen" animationData={solarNgen} />
            )}
            {data.gui.battery > 0 && deviceType === "ngenstar" && firstMessageReceived && (
              <LottieAnimation id="batNgen" animationData={batNgen} />
            )}
            {data.gui.battery < 0 && deviceType === "ngenstar" && firstMessageReceived && (
              <LottieAnimation id="ngenBat" animationData={ngenBat} />
            )}
            {data.gui.load > 0 && deviceType === "ngenstar" && firstMessageReceived && (
              <LottieAnimation id="ngenHisa" animationData={ngenHisa} />
            )}
            {data.gui.load < 0 && deviceType === "ngenstar" && firstMessageReceived && (
              <LottieAnimation id="hisaNgen" animationData={hisaNgen} />
            )}
            {data.gui.grid > 0 && deviceType === "ngenstar" && firstMessageReceived && (
              <LottieAnimation id="omaricaNgen" animationData={omaricaNgen} />
            )}
            {data.gui.grid < 0 && deviceType === "ngenstar" && firstMessageReceived && (
              <LottieAnimation id="ngenOmarica" animationData={ngenOmarica} />
            )}
          </>
        )}

        {smartgrid && deviceType === "ngenstar" && firstMessageReceived && <LottieAnimation id="scHeatpump" animationData={toplotna} />}
        {smartgrid && deviceType === "smart-meter" && Math.abs(smartMeter.power || 0) > 0 && firstMessageReceived && (
          <LottieAnimation id="scHeatpump" animationData={toplotna} />
        )}
        {deviceType === "smart-meter" && Math.abs(smartMeter.power || 0) >= 50 && firstMessageReceived && (
          <>
            {(smartMeter.power || 0) >= 0 ? (
              <>
                <LottieAnimation id="omaricaNgen" animationData={omaricaNgen} />
                <LottieAnimation id="ngenHisa" animationData={ngenHisa} />
              </>
            ) : (
              <>
                <LottieAnimation id="ngenOmarica" animationData={ngenOmarica} />
                <LottieAnimation id="hisaNgen" animationData={hisaNgen} />
              </>
            )}
          </>
        )}

        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="100%"
          height="100%"
          viewBox="0 200 1082 1189"
        >
          <defs>
            {inverterState !== 3 && deviceType === "ngenstar" && (
              <clipPath id="o593a">
                <path
                  fill="#fff"
                  d="M688.448 1103c6.351 0 11.5 5.149 11.5 11.5s-5.149 11.5-11.5 11.5-11.5-5.149-11.5-11.5 5.149-11.5 11.5-11.5z"
                />
              </clipPath>
            )}
            <clipPath id="o593b">
              <path
                fill="#fff"
                d="M178.5 1103c6.351 0 11.5 5.149 11.5 11.5s-5.149 11.5-11.5 11.5-11.5-5.149-11.5-11.5 5.149-11.5 11.5-11.5z"
              />
            </clipPath>
            <clipPath id="o593c">
              <path
                fill="#fff"
                d="M364.5 521c6.351 0 11.5 5.149 11.5 11.5S370.85 544 364.5 544 353 538.85 353 532.5s5.149-11.5 11.5-11.5z"
              />
            </clipPath>
            <clipPath id="o593d">
              <path
                fill="#fff"
                d="M735.5 823c6.351 0 11.5 5.148 11.5 11.5 0 6.35-5.149 11.5-11.5 11.5-6.352 0-11.5-5.15-11.5-11.5 0-6.352 5.148-11.5 11.5-11.5z"
              />
            </clipPath>
          </defs>
          <g id="timestamp">
            <text fill="white" fontFamily="inherit" fontSize="28" transform="translate(776 1360)">
              {shouldDisplayTimestamp() && <tspan dy="0.8em">{getFormattedTimestamp()}</tspan>}
            </text>
          </g>
          <g>
            <g>
              <image
                width="1082"
                height="1298"
                transform="translate(0 191)"
                xlinkHref={
                  deviceType === "smart-meter"
                    ? smartMeterImg
                    : deviceType === "powerbank"
                      ? industryImg
                      : deviceType === "ngenstar" && smartgrid
                        ? hisa
                        : nstartNoSC
                }
              />
            </g>
            <g>
              {inverterState !== 3 && deviceType === "ngenstar" && (
                <g>
                  <path fill="#7e8a8a" d="M689 1311h-1v-190h1z" />
                </g>
              )}
              {inverterState !== 3 && deviceType === "ngenstar" && (
                <g>
                  <path
                    id="gridDot"
                    fill="#7e8a8a"
                    d="M688.448 1103c6.351 0 11.5 5.149 11.5 11.5s-5.149 11.5-11.5 11.5-11.5-5.149-11.5-11.5 5.149-11.5 11.5-11.5z"
                  />
                  <path
                    fill="none"
                    stroke="#5eba47"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="20"
                    strokeWidth="12"
                    d="M688.448 1103c6.351 0 11.5 5.149 11.5 11.5s-5.149 11.5-11.5 11.5-11.5-5.149-11.5-11.5 5.149-11.5 11.5-11.5z"
                    clipPath='url("#o593a")'
                  />
                </g>
              )}

              <g
                id="gridOpen"
                onClick={() => {
                  if (deviceType === "ngenstar" || deviceType === "smart-meter") {
                    energyGrid();
                  }
                }}
              >
                <g>
                  <g>
                    {((inverterState !== 3 && deviceType === "ngenstar") || deviceType !== "ngenstar") && (
                      <g transform="translate(706 1274)">
                        {(deviceType !== "ngenstar" && smartMeter.power === null) || (deviceType === "ngenstar" && data.grid === null) ? (
                          <DotSpinner />
                        ) : (
                          <text fill="#fff" fontFamily="inherit" fontSize="3rem">
                            <tspan dy="0.8em">
                              {deviceType === "ngenstar" ? `${Math.abs(data.gui?.grid)} kW` : `${formatPower(smartMeter["power"])} kW`}
                            </tspan>
                          </text>
                        )}
                      </g>
                    )}
                  </g>
                </g>
                <g>
                  <g>
                    <text
                      fill={inverterState === 3 ? "red" : "#5eba47"}
                      fontFamily="inherit"
                      fontSize="2rem"
                      transform="translate(706 1235)"
                    >
                      <tspan dy="0.6em">
                        {inverterState === 3 && deviceType === "ngenstar" ? t("animation.grid-disconnected") : t("animation.grid")}
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
            <g id="batteryWindow" className={deviceType === "smart-meter" ? "hidden" : ""}>
              <g>
                <path fill="#7e8a8a" d="M178 1121h1v235h-1z" />
              </g>
              <g>
                <path
                  fill="#7e8a8a"
                  d="M178.5 1103c6.351 0 11.5 5.149 11.5 11.5s-5.149 11.5-11.5 11.5-11.5-5.149-11.5-11.5 5.149-11.5 11.5-11.5z"
                />
                <path
                  fill="none"
                  stroke="#5eba47"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="20"
                  strokeWidth="12"
                  d="M178.5 1103c6.351 0 11.5 5.149 11.5 11.5s-5.149 11.5-11.5 11.5-11.5-5.149-11.5-11.5 5.149-11.5 11.5-11.5z"
                  clipPath='url("#o593b")'
                />
              </g>
              <g>
                <g onClick={energyBattery}>
                  <g>
                    <g transform="translate(192 1274)">
                      {deviceType === "ngenstar" && data.grid === null ? (
                        <DotSpinner />
                      ) : (
                        <text fill="#fff" xmlSpace="preserve" fontFamily="inherit" fontSize="3rem">
                          <tspan dy="0.8em" dx="0.1em">{`${Math.abs(data.gui?.battery)} kW - ${batteryStats.soc}%`}</tspan>
                        </text>
                      )}
                    </g>
                  </g>
                  <g>
                    <g>
                      <text fill="#5eba47" xmlSpace="preserve" fontFamily="inherit" fontSize="2rem" transform="translate(196 1235)">
                        <tspan dy="0.6em">{t("animation.storage")}</tspan>
                      </text>
                    </g>
                  </g>
                  <g id="batteryStatus">
                    <g>
                      <text
                        fill={getBatStatus(data.gui?.battery).color}
                        xmlSpace="preserve"
                        fontFamily="inherit"
                        fontSize="1.6rem"
                        transform="translate(196 1330)"
                      >
                        <tspan dy="1em">{getBatStatus(data.gui?.battery).status}</tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g id="batteryLine" className={deviceType === "smart-meter" ? "hidden" : ""}>
              <g>
                <path fill="#141317" d={`M142 950l9 2v168l-9-2z`} />
              </g>
              <g>
                <path
                  fill="#1fde2d"
                  d={`M142 ${950 + (168 * (100 - batteryStats.soc)) / 100}l9 2v${168 * (batteryStats.soc / 100)}l-9-2z`}
                />
              </g>
            </g>
            <g id="solarWindow" className={deviceType === "smart-meter" ? "hidden" : ""} onClick={handleClick}>
              <g>
                <g onClick={energySolar}>
                  <g>
                    <g transform="translate(384 240)">
                      {deviceType === "ngenstar" && data.grid === null ? (
                        <DotSpinner />
                      ) : (
                        <text fill="#fff" fontFamily="inherit" fontSize="3rem" xmlSpace="preserve">
                          <tspan dy="1em">{`${Math.abs(data.gui?.photovoltaic)} kW`}</tspan>
                        </text>
                      )}
                    </g>
                  </g>
                  <g>
                    <g>
                      <text fill="#5eba47" xmlSpace="preserve" fontFamily="inherit" fontSize="2rem" transform="translate(383 203)">
                        <tspan dy="0.8em">{t("animation.solar")}</tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </g>
              <g>
                <path fill="#7e8a8a" d="M364 203h1v320h-1z" />
              </g>
              <g>
                <path
                  fill="#7e8a8a"
                  d="M364.5 521c6.351 0 11.5 5.149 11.5 11.5S370.85 544 364.5 544 353 538.85 353 532.5s5.149-11.5 11.5-11.5z"
                />
                <path
                  fill="none"
                  stroke="#5eba47"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="20"
                  strokeWidth="12"
                  d="M364.5 521c6.351 0 11.5 5.149 11.5 11.5S370.85 544 364.5 544 353 538.85 353 532.5s5.149-11.5 11.5-11.5z"
                  clipPath='url("#o593c")'
                />
              </g>
            </g>
            <g id="homeWindow">
              <g>
                <path fill="#7e8a8a" d="M735 203h1v630h-1z" />
              </g>
              <g>
                <path
                  fill="#7e8a8a"
                  d="M735.5 823c6.351 0 11.5 5.148 11.5 11.5 0 6.35-5.149 11.5-11.5 11.5-6.352 0-11.5-5.15-11.5-11.5 0-6.352 5.148-11.5 11.5-11.5z"
                />
                <path
                  fill="none"
                  stroke="#5eba47"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="20"
                  strokeWidth="12"
                  d="M735.5 823c6.351 0 11.5 5.148 11.5 11.5 0 6.35-5.149 11.5-11.5 11.5-6.352 0-11.5-5.15-11.5-11.5 0-6.352 5.148-11.5 11.5-11.5z"
                  clipPath='url("#o593d")'
                />
              </g>
              <g>
                <g
                  onClick={() => {
                    if (deviceType === "ngenstar" || deviceType === "smart-meter") {
                      energyHome();
                    }
                  }}
                >
                  <g>
                    <g>
                      <g transform="translate(755 240)">
                        {(deviceType !== "ngenstar" && smartMeter.power === null) || (deviceType === "ngenstar" && data.grid === null) ? (
                          <DotSpinner />
                        ) : (
                          <text fill="#fff" xmlSpace="preserve" fontFamily="inherit" fontSize="3rem">
                            <tspan dy="1em">
                              {deviceType === "ngenstar" ? `${Math.abs(data.gui?.load)} kW` : `${formatPower(smartMeter["power"])} kW`}
                            </tspan>
                          </text>
                        )}
                      </g>
                    </g>
                  </g>
                  <g>
                    <g>
                      <text fill="#5eba47" xmlSpace="preserve" fontFamily="inherit" fontSize="2rem" transform="translate(756 203)">
                        <tspan dy="0.8em">{t("animation.home")}</tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          {inverterState === 3 && deviceType === "ngenstar" && (
            <g id="warrningGrid">
              <path
                fill="none"
                stroke="red"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="20"
                strokeWidth="24"
                d="M100,100 L200,200 M200,100 L100,200"
                transform="translate(706 1073)  scale(0.4)"
              />
            </g>
          )}
          {inverterState !== 2 && deviceType === "ngenstar" && firstMessageReceived && (
            <g transform="rotate(5, 526, 934)">
              <ellipse id="ovalSemafor" cx="526" cy="934" rx="11" ry="15" fill={inverterStateStyles[inverterState].fill} />
              <line x1="515" y1="934" x2="450" y2="954" stroke="#7e8a8a" strokeWidth="2" />
              <line x1="450" y1="954" x2="468" y2="1164" stroke="#7e8a8a" strokeWidth="2" />
            </g>
          )}
          {inverterState !== 2 && deviceType === "ngenstar" && firstMessageReceived && (
            <g>
              <text
                fill={inverterStateStyles[inverterState].fill}
                fontFamily="inherit"
                fontSize="2rem"
                transform={inverterStateStyles[inverterState].transform}
              >
                <tspan dy="0.8em">{inverterStateStyles[inverterState].title}</tspan>
              </text>
            </g>
          )}

          {deviceType === "ngenstar" && firstMessageReceived && batteryStats.bms2 != null && <NgenStarProBadge />}
        </svg>
      </div>
    </div>
  );
};

export default Animation;
