import Icon from "@mdi/react";
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { forwardRef, Fragment, useCallback, useImperativeHandle, useState } from "react";
import { mdiClose } from "@mdi/js";

export type ILastStateDialog = {
  showDialog: (entityName: string, state: { value: any; user: any }) => void;
};

export const LastStateDialog = forwardRef<ILastStateDialog>(function _LastStateDialog(_, ref) {
  useImperativeHandle(ref, () => ({ showDialog }));

  const [state, setState] = useState<{
    isOpen: boolean;
    value: any;
    user: any;
    entityName: string;
  }>({
    isOpen: false,
    value: undefined,
    user: undefined,
    entityName: "",
  });

  const showDialog = useCallback((entityName: string, state: { value: any; user: any }) => {
    setState({
      isOpen: true,
      value: state.value,
      user: state.user,
      entityName,
    });
  }, []);

  const closeDialog = useCallback(() => {
    setState((prevState) => ({ ...prevState, isOpen: false }));
  }, []);

  return (
    <Transition show={state.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeDialog}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-brand-500 bg-opacity-50  transition-opacity dark:bg-brand-900 dark:bg-opacity-50" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto  text-brand-950 dark:text-brand-100">
          <div className="flex min-h-full items-end justify-center p-2 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-brand-50 px-4 pb-4 pt-5 text-left shadow-xl transition-all dark:bg-brand-950 sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md text-brand-500 hover:text-brand-700 focus:outline-none  "
                    onClick={closeDialog}
                  >
                    <span className="sr-only">Close</span>
                    <Icon path={mdiClose} className="h-6 w-6" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" className="text-base font-semibold leading-6">
                      Last change details
                    </DialogTitle>
                    <div className="mt-2">
                      <p className="mb-2  rounded bg-brand-500/5 p-2 text-sm text-brand-700 dark:text-brand-300">
                        <div className="mb-2 flex items-center gap-2 border-b border-b-brand-700 pb-2">
                          <span className="font-semibold">Property:</span>
                          <span className="text-brand-500">{state.entityName} </span>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="font-semibold">User:</span>
                          <span className=" text-blue-400">{state.user || "N/A"} </span>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="font-semibold">Datetime:</span>
                          <span className=" text-brand-500">{"N/A"} </span>
                        </div>
                      </p>
                      <p className="text-balance rounded bg-orange-400/5 p-2 text-xs italic text-brand-700 dark:text-brand-300">
                        <span className=" text-orange-400">Warning:</span> This value could be changed while you are viewing this.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full items-center justify-center gap-2 rounded-md bg-brand-700 px-3 py-2 text-sm font-semibold text-brand-50 shadow-sm ring-1 ring-inset ring-brand-700 hover:bg-brand-600 sm:mt-0 sm:w-auto"
                    onClick={closeDialog}
                  >
                    <Icon path={mdiClose} className="size-4" />
                    Close
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
});
