import Icon from "@mdi/react";
import moment from "moment";
import { DeviceStatus } from "../../../../deviceList/_common/DeviceStatus";
import { LoadingIcon } from "../../../../../decorators/loading/Loading";
import { mdiUpdate } from "@mdi/js";
import { useId, useState } from "react";
import { useMqttProperty } from "../../../../../hooks/useMqttClient";
import { Value } from "../_shared/Value";

export function DeviceStatusInfo() {
  const componentId = useId();

  const [state, setState] = useState<{
    isLoading: boolean;
    status: string;
    dateTime: string;
  }>({
    isLoading: true,
    status: "N/A",
    dateTime: "",
  });

  useMqttProperty(
    "status",
    componentId,
    10_000,
    (data: {
      status: string;
      metadata: {
        utc_timestamp: number;
      };
    }) => {
      setState({
        status: data.status,
        dateTime:
          data.metadata.utc_timestamp != null
            ? moment.tz(data.metadata.utc_timestamp * 1000, "Europe/Ljubljana").format("DD.MM.yyyy HH:mm:ss")
            : "N/A",
        isLoading: false,
      });
    }
  );

  return (
    <Value
      label="Service status"
      value={
        <div className="mt-2">
          <div className="flex items-center gap-2">
            <DeviceStatus status={state.status} />
            {state.isLoading && <LoadingIcon />}
            {state.dateTime && (
              <div className="flex items-center gap-1 font-mono text-xs font-extralight italic text-brand-500">
                <Icon path={mdiUpdate} className="h-3 w-3" />
                {state.dateTime}
              </div>
            )}
          </div>
        </div>
      }
    />
  );
}
