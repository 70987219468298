import SGConnectImgSrc from "./assets/sgconnect.png";
import { clsx } from "../../utils/clsx";
import { CountryCard } from "./CountryCard";
import { deviceImages, REFRESH_TIMEOUT } from "./utils/const";
import { calculateFreshDevices, generateDeviceName, generateDevicePlantTypeName, generateResult, timeAgoHumanized } from "./utils/utils";
import type { IDevice } from "../../types/Device";
import { InfiniteLoopSlider } from "./InfiniteLoopSlider";
import type { ICountry, IResult } from "./types/types";
import { Loading } from "../../decorators/loading/Loading";
import { RestEnv } from "../../services/restService/restUtils";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetData } from "../../hooks/useGetData";
import { type INotificationRef, Notification } from "./Notification";

export function Dashboard(): React.JSX.Element {
  const [data, setData] = useState<IResult | undefined>(undefined);
  const [lastUpdate, setLastUpdate] = useState<Date | undefined>(undefined);
  const dataRef = useRef<IResult | undefined>(undefined);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const notificationRef = useRef<INotificationRef>(null);
  const [searchParams] = useSearchParams();
  const useGifs = useRef<boolean>(searchParams.get("useGifs") === "true");

  const fetch = useGetData<IResult>({
    request: {
      env: RestEnv.DATA,
      path: "/api/1.0/device/list",
    },
    mapData: useCallback(({ data }: { data: IDevice[] }) => {
      setData(generateResult(data));
      setLastUpdate(new Date());

      // Used for testing purposes.
      // setInterval(() => {
      //   const newData = addRandomDevice(data);
      //   setData(generateResult(newData));
      // }, 10_000);

      // Used for testing notification.
      // setTimeout(() => {
      //   const d = generateResult(data);
      //   d.byDevice.ngenstar.total = 2500;
      //   setData(d);
      // }, 3000);
    }, []),
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!fetch.isLoading && !fetch.error) {
        fetch.fetchData();
      }
    }, REFRESH_TIMEOUT);

    return () => clearTimeout(timeout);
  }, [fetch]);

  useEffect(() => {
    setCountries(
      Object.entries(data?.byCountry || {}).reduce((acc, [name, country]) => {
        const showThisCountry = country.total > 0;

        if (showThisCountry) {
          acc.push(country);
        }

        return acc;
      }, [] as ICountry[]),
    );

    if (!dataRef.current) {
      dataRef.current = data;
      return;
    }

    if (data) {
      const count = calculateFreshDevices(dataRef.current, data);
      if (count > 0) {
        notificationRef.current?.open({ count, autoClose: true });
      }

      dataRef.current = data;
    }
  }, [data]);

  if (!data) {
    return <Loading text="Fetching devices ..." iconPosition="top" />;
  }

  return (
    <div className="flex min-h-screen w-screen items-center justify-center overflow-scroll bg-brand-900 text-brand-100">
      <div className="flex w-full max-w-screen-tv flex-col gap-6">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div className="relative flex flex-col gap-8 bg-brand-950 p-6 pt-14 shadow-lg">
            <div className="absolute left-0 top-0 p-2 tv:-left-14 tv:-top-5">
              <img src={SGConnectImgSrc} alt="SG Connect" className="h-8 tv:h-12" />
            </div>

            {Object.entries(data.byDevice || {}).map(([device, data]) => (
              <div key={device} className="flex w-full justify-center gap-5 text-2xl">
                <div>
                  {deviceImages[device] ? (
                    <img src={deviceImages[device]} className="h-8 w-8" alt={generateDeviceName(device)} />
                  ) : (
                    <div className="h-8 w-8" />
                  )}
                </div>
                {device !== "smart-meter" ? (
                  <>
                    <div className="grow uppercase">{generateDeviceName(device)}</div>
                    <div className="text-ngen">{data.total}</div>
                  </>
                ) : (
                  <div className="flex w-full justify-center gap-5 text-2xl">
                    <div className="flex grow flex-col gap-2">
                      <div className="flex items-center">
                        <div className="grow">{generateDeviceName(device)}</div>
                        <div className="text-ngen">{data.total}</div>
                      </div>
                      <div className="flex gap-2">
                        <div className="rotate-180 text-center text-sm uppercase text-ngen [writing-mode:vertical-lr]">Powered by</div>
                        <div className="flex grow flex-col gap-1 text-sm">
                          {Object.entries(data.totalByType || {}).map(([devicePlantType, d]) => (
                            <div key={devicePlantType} className="flex items-center">
                              <div className="grow uppercase text-brand-500">{generateDevicePlantTypeName(devicePlantType)}</div>
                              <div className="text-brand-50">{d}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="relative flex flex-col gap-3 bg-brand-950 p-6 pt-8 shadow-lg">
            <div className="flex w-full justify-center text-4xl">
              <span>
                <span className="font-semibold text-ngen">LIVE</span> DATA TOTAL
              </span>

              <span className="relative flex h-3 w-3">
                <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-ngen opacity-75"></span>
                <span className="relative inline-flex h-3 w-3 rounded-full bg-ngen"></span>
              </span>
            </div>

            <div>
              <table className="w-full border-collapse text-sm">
                <thead className="">
                  <tr>
                    <th></th>
                    <th className="p-3 text-brand-500">THIS WEEK</th>
                    <th className="p-3 text-brand-500">THIS MONTH</th>
                    <th className="p-3 text-brand-500">TOGETHER</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(data.byDevice || {}).map(([device, data]) => (
                    <tr key={device}>
                      <td className="text-base uppercase leading-5">{generateDeviceName(device)}</td>
                      <td
                        className={clsx(
                          "border border-brand-800 bg-brand-900 p-3 text-center normal-nums leading-8",
                          data.thisWeek ? "text-2xl" : "text-xl text-brand-500",
                        )}
                      >
                        + {data.thisWeek}
                      </td>
                      <td
                        className={clsx(
                          "border border-brand-800 bg-brand-900 p-3 text-center normal-nums leading-8",
                          data.thisMonth ? "text-2xl" : "text-xl text-brand-500",
                        )}
                      >
                        + {data.thisMonth}
                      </td>
                      <td
                        className={clsx(
                          "border border-brand-800 bg-brand-900 p-3 text-center normal-nums leading-8",
                          data.total ? "text-2xl" : "text-xl text-brand-500",
                        )}
                      >
                        {data.total}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="w-full text-center">{lastUpdate && <LastUpdate date={lastUpdate} />}</div>
          </div>
        </div>

        <InfiniteLoopSlider duration={(countries.length || 4) * 20}>
          {countries
            .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
            .map((countryData) => (
              <CountryCard data={countryData} useGifs={useGifs.current} key={countryData.iso} />
            ))}
        </InfiniteLoopSlider>
      </div>

      <Notification ref={notificationRef} />
    </div>
  );
}

function LastUpdate({ date }: { date: Date }): React.JSX.Element {
  const [timeAgo, setTimeAgo] = useState<string>(timeAgoHumanized(date));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeAgo(timeAgoHumanized(date));
    }, 1000);

    return () => clearInterval(interval);
  }, [date]);

  return <span className="font-mono text-xs font-medium italic text-brand-500">Last update: {timeAgo}</span>;
}
