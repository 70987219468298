import Icon from "@mdi/react";
import { CSSProperties, ReactNode, useState } from "react";
import { mdiChevronDoubleDown, mdiChevronDoubleUp } from "@mdi/js";
import { PermanentUserPreferences } from "../../services/userPeferencesService/userPreferencesService";

export default function Container(props: {
  title: string;
  icon?: string;
  isCollapseable?: boolean;
  titleRightContnent?: ReactNode;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  hideTitle?: boolean;
  expandedStyle?: CSSProperties;
  variant?: "big" | "small";
}) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    props.isCollapseable ? PermanentUserPreferences.get("container", props.title, false) : false
  );

  const toggleCollapse = () => {
    const newState = !isCollapsed;
    PermanentUserPreferences.set("container", props.title, newState);
    setIsCollapsed(newState);
  };

  const def = (() => {
    return { hideBorder: props.variant === "small", titleClassName: props.variant === "small" ? "text-sm my-1" : "text-lg" };
  })();

  return (
    <div className={props.className} style={{ ...props.style, ...(!isCollapsed && props.expandedStyle) }}>
      {!props.hideTitle && (
        <div
          className={
            "flex items-baseline justify-between " +
            (isCollapsed || def.hideBorder ? "" : "mb-2 border-b-2 border-brand-100 pb-2 dark:border-b dark:border-brand-900")
          }
        >
          <div
            className={"flex items-center gap-2 " + (props.isCollapseable ? "hover:cursor-pointer" : "")}
            onClick={props.isCollapseable ? toggleCollapse : undefined}
          >
            {props.isCollapseable && (
              <Icon
                path={isCollapsed ? mdiChevronDoubleDown : mdiChevronDoubleUp}
                className={props.variant === "small" ? "h-3 w-3" : "h-4 w-4"}
              />
            )}
            {props.icon && <Icon path={props.icon} className="h-4 w-4" />}
            <div id={props.title} className={`font-semibold ${def.titleClassName}`}>
              {props.title}
            </div>
          </div>
          {props.titleRightContnent}
        </div>
      )}

      {!isCollapsed && <div className="w-full">{props.children}</div>}
    </div>
  );
}
