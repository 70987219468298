import Icon from "@mdi/react";
import { mdiClose, mdiRefresh } from "@mdi/js";
import { useNavigate } from "react-router-dom";

export default function DeviceListHeader(props: { title: string; onClearNavigateTo: string; query: string | null; onRefresh: () => void }) {
  const navigate = useNavigate();
  return (
    <div className="relative">
      <div className="md:flex md:items-center md:justify-between">
        <h3 className="text-lg font-medium leading-6">
          {props.title}
          {props.query && (
            <>
              &nbsp;matching search criteria `<span className="font-medium text-ngen">{props.query}</span>`
            </>
          )}
        </h3>

        <div className="mt-3 flex gap-3 md:absolute md:right-0 md:top-0 md:mt-0">
          <button
            type="button"
            onClick={props.onRefresh}
            className="inline-flex items-center gap-1 rounded-md bg-brand-100 px-3 py-2 text-sm font-semibold shadow-sm hover:text-ngen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ngen dark:bg-brand-900"
          >
            <Icon path={mdiRefresh} className="-mb-0.5 h-4 w-4" />
            Refresh
          </button>
          {props.query && (
            <button
              type="button"
              onClick={() => {
                navigate(props.onClearNavigateTo);
              }}
              className="inline-flex items-center gap-1 rounded-md bg-brand-100 px-3 py-2 text-sm font-semibold shadow-sm hover:text-ngen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ngen dark:bg-brand-900"
            >
              <Icon path={mdiClose} className="-mb-0.5 h-4 w-4" />
              Clear filter
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
