import { createContext, useContext } from "react";
import { IUser, useUserLoginSystem } from "../../hooks/useUser";
import { Loading } from "../../decorators/loading/Loading";
import { UserLoginForm } from "../userLoginForm/UserLoginForm";

const UserContext = createContext<{
  isLogged?: boolean;
  logUserIn?: (id: string, pass: string) => void;
  logUserOut?: () => void;
  user?: IUser;
}>({});

export function UserRestrictedArea(props: { children: any }) {
  const { isLogged, logUserIn, state, logUserOut, user } = useUserLoginSystem();

  if (state === "AUTO-LOGINING") {
    return (
      <div className="flex h-screen w-screen items-center justify-center bg-gradient-to-tl from-brand-950  to-brand-50 dark:to-brand-500">
        <div className="w-80 rounded bg-brand-50 bg-opacity-80 dark:bg-brand-950">
          <Loading text="Trying to use previous user session token ..." iconPosition="top" className="pt-4" />
        </div>
      </div>
    );
  }

  if (!isLogged) {
    return <UserLoginForm logUserIn={logUserIn} isLoading={state === "LOGINING"} />;
  }

  return <UserContext.Provider value={{ isLogged, logUserIn, logUserOut, user }}>{props.children}</UserContext.Provider>;
}

export function useUser() {
  return useContext(UserContext);
}
