import ContentSelector from "../_common/ContentSelector";
import DeviceListTable from "../DeviceListTable";
import Icon from "@mdi/react";
import { getDevicesByCountry, getDevicesByProvisionGroup, getDevicesBySupplyGroup, getDevicesByType } from "./DeviceListContent.utils";
import { IDevice } from "../../../types/Device";
import { mdiInformation } from "@mdi/js";
import { useCallback, useState } from "react";

export function DeviceListContent(props: { devices: IDevice[] }) {
  const [groupedData] = useState(getDevicesByCountry(props.devices));

  const [state, setState] = useState(groupedData[0]);

  const onTabSelection = useCallback(
    (tabKey: string) => {
      setState(groupedData.find((g) => g.groupKey === tabKey) || groupedData[0]);
    },
    [groupedData]
  );

  return (
    <div className="mt-4 flex flex-col gap-2">
      <ContentSelector
        selectedKey={state.groupKey}
        onSelection={onTabSelection}
        items={groupedData.map((gd) => ({ key: gd.groupKey, name: gd.groupName, badge: gd.devices.length }))}
      />
      <DeviceListContentByType devices={state.devices} key={state.groupKey} />
    </div>
  );
}

export function DeviceListContentByType(props: { devices: IDevice[] }) {
  const [groupedData] = useState(getDevicesByType(props.devices));

  const [state, setState] = useState(groupedData[0]);

  const onTabSelection = useCallback(
    (tabKey: string) => {
      setState(groupedData.find((g) => g.groupKey === tabKey) || groupedData[0]);
    },
    [groupedData]
  );

  return (
    <div className="flex flex-col gap-2">
      <ContentSelector
        selectedKey={state.groupKey}
        onSelection={onTabSelection}
        items={groupedData.map((gd) => ({ key: gd.groupKey, name: gd.groupName, badge: gd.devices.length }))}
      />
      <DeviceListContentByPrivisionGroup devices={state.devices} key={state.groupKey} />
    </div>
  );
}

function DeviceListContentByPrivisionGroup(props: { devices: IDevice[] }) {
  const [groupedData] = useState(getDevicesByProvisionGroup(props.devices));

  const [state, setState] = useState(groupedData[0]);

  const onTabSelection = useCallback(
    (tabKey: string) => {
      setState(groupedData.find((g) => g.groupKey === tabKey) || groupedData[0]);
    },
    [groupedData]
  );

  return (
    <>
      <ContentSelector
        selectedKey={state.groupKey}
        onSelection={onTabSelection}
        items={groupedData.map((gd) => ({ key: gd.groupKey, name: gd.groupName, badge: gd.devices.length }))}
      />

      {state.groupKey === "provisionedSupply" ? (
        <DeviceListContentBySupplyGroup devices={state.devices} />
      ) : (
        <div className="mt-4">
          {state.groupKey === "devicesNeededSupport" && state.devices.length > 0 && <DeviceNeededSupportDef />}
          <DeviceListTable data={state.devices} />
        </div>
      )}
    </>
  );
}

function DeviceListContentBySupplyGroup(props: { devices: IDevice[] }) {
  const [groupedData] = useState(getDevicesBySupplyGroup(props.devices));

  const [state, setState] = useState(groupedData[0]);

  const onTabSelection = useCallback(
    (tabKey: string) => {
      setState(groupedData.find((g) => g.groupKey === tabKey) || groupedData[0]);
    },
    [groupedData]
  );

  return (
    <>
      <ContentSelector
        selectedKey={state.groupKey}
        onSelection={onTabSelection}
        items={groupedData.map((gd) => ({ key: gd.groupKey, name: gd.groupName, badge: gd.devices.length }))}
      />
      <div className="mt-4">
        <DeviceListTable data={state.devices} />
      </div>
    </>
  );
}

export default function DeviceNeededSupportDef() {
  return (
    <div className="mb-4 rounded-md bg-orange-50 p-4 shadow dark:bg-orange-900/30">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <Icon path={mdiInformation} className="h-5 w-5" />
        </div>
        <div className="ml-3 ">
          <p className="text-sm">The devices listed below have specified supply dates, but their status is currently NOT online.</p>
          <p className="text-xs italic">
            These devices appear in their original groups as well; here, they are presented solely for better representation.
          </p>
        </div>
      </div>
    </div>
  );
}
