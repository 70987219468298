import Icon from "@mdi/react";
import { clsx } from "../../../utils/clsx";
import { LoadingIcon } from "../../../decorators/loading/Loading";
import { mdiAccountEditOutline, mdiHistory, mdiSquareEditOutline } from "@mdi/js";
import { RegisterOptions } from "react-hook-form";
import { useEditStatusValueDialog } from "./editStateDialog/StateDialog.utils";
import { ReactNode, useId, useState } from "react";
import { useMqttCommandProperty } from "../../../hooks/useMqttClient";
import { useUser } from "../../../layout/userRestrictedArea/UserRestrictedArea";

export default function StatusValue(props: {
  icon: string;
  value: any;
  unit?: string;
  description: any;
  userEditable?: boolean;
  editable?: {
    dataKey: string;
    dataValue?: string | number | boolean;
    type: "textInput" | "numberInput" | "comboBoxInput";
    options?: { key: string; value: string | number }[];
    warning?: string;
    validation?: RegisterOptions;
  };
  isLoading?: boolean;
}) {
  const componentId = useId();
  const { showEditDialog, viewLastChangeDialog } = useEditStatusValueDialog();
  const [requiredValue, setRequiredValue] = useState<undefined | { value: any; user: any }>(undefined);
  const { user } = useUser();
  const hasWriteAccess = user?.permissions.nexusCommandsAccessMode?.includes("w");

  const editValue = () => {
    if (props.editable) {
      showEditDialog?.("Edit value", {
        dataKey: props.editable.dataKey,
        dataLabel: props.description,
        dataValue: props.editable.dataValue != null ? props.editable.dataValue : props.value,
        type: props.editable.type,
        unit: props.unit,
        options: props.editable.options,
        warning: props.editable.warning,
        validation: props.editable.validation,
      });
    }
  };

  const viewLastChange = () => {
    if (props.editable) {
      viewLastChangeDialog?.(props.description, { value: requiredValue?.value, user: requiredValue?.user });
    }
  };

  useMqttCommandProperty(props.editable?.dataKey, componentId, (obj: { value: any; platform: any; user: any }) => {
    setRequiredValue({ value: obj.value, user: obj.user });
  });

  return (
    <>
      <div className="flex w-full items-center gap-2 rounded-md border border-brand-100 bg-brand-50 p-3 shadow-sm dark:border-brand-800 dark:bg-brand-900">
        <div className="flex-grow">
          <div className="flex items-center gap-2">
            <Icon path={props.icon} className="h-4 w-4" />
            <div className="flex items-center gap-2">
              <span className="font-semibold uppercase">
                {props.value != null ? props.value + (props.unit ? ` ${props.unit}` : "") : "N/A"}
              </span>
              {props.isLoading && <LoadingIcon />}
              {requiredValue?.value != null &&
                requiredValue?.value?.toString() !==
                  (props.editable?.dataValue != null ? props.editable.dataValue : props.value)?.toString() && (
                  <span className="font-thin text-brand-500">
                    Wanted:{" "}
                    {props.editable?.options
                      ? props.editable.options.find((o) => o.key === requiredValue?.value)?.value || requiredValue?.value
                      : requiredValue?.value}{" "}
                    {props.unit}
                  </span>
                )}
            </div>
          </div>

          <div className="text-sm">{props.description}</div>
        </div>

        {props.userEditable && (
          <div className="text-brand-500">
            <NTooltip text="User-adjustable option">
              <Icon path={mdiAccountEditOutline} className="-mb-1 h-6 w-6" />
            </NTooltip>
          </div>
        )}

        {props.editable && !props.isLoading && hasWriteAccess && (
          <>
            <div className="hover:cursor-pointer hover:text-green-500" onClick={viewLastChange}>
              <Icon path={mdiHistory} className="-mb-1 h-6 w-6" />
            </div>
            <div className="hover:cursor-pointer hover:text-green-500" onClick={editValue}>
              <Icon path={mdiSquareEditOutline} className="-mb-1 h-6 w-6" />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export const NTooltip = (props: { text: ReactNode | undefined; children: any; position?: "right" | "left" }) => {
  return (
    <div className="group relative hover:cursor-help">
      {props.children}

      {props.text && (
        <div
          className={clsx(
            "absolute top-6 z-10 hidden whitespace-nowrap rounded bg-brand-800 p-2 text-xs text-white shadow group-hover:inline",
            props.position === "right" ? "right-0" : "left-0",
          )}
        >
          {props.text}
        </div>
      )}
    </div>
  );
};
