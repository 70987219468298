import Highcharts from "highcharts";
import { addDays, addMonths, endOfDay, endOfMonth, endOfYear, format, startOfDay, startOfMonth, startOfYear, sub } from "date-fns";
import { UTCDate } from "@date-fns/utc";
import { IDevicePlantType } from "../../../../../../../types/Device";

type IconConfig = {
  [key: number]: {
    name: string;
    dataKey: string;
    fillColor: string;
    colorIndex: number;
    id?: number;
  }[];
};

const minChartVal = 2;
const minChartMonth = 15;

const iconConfig: IconConfig = {
  0: [
    { name: "energy.Home", dataKey: "loadData", fillColor: "rgba(56, 189, 248,0.2)", colorIndex: 0, id: 1 },
    {
      name: "energy.photovoltaic",
      dataKey: "photovoltaicData",
      fillColor: "rgba(253, 224, 71, 0.2)",
      colorIndex: 1,
      id: 2,
    },
    { name: "energy.battery", dataKey: "batteryData", fillColor: "rgba(94,185,70, 0.2)", colorIndex: 2, id: 3 },
    { name: "energy.grid", dataKey: "gridData", fillColor: "rgba(229, 231, 235, 0.2)", colorIndex: 3, id: 4 },
  ],
  1: [
    {
      name: "energy.photovoltaic",
      dataKey: "loadPhotovoltaicData",
      fillColor: "rgba(253, 224, 71, 0.2)",
      colorIndex: 1,
    },
    { name: "energy.battery", dataKey: "loadBatteryData", fillColor: "rgba(94,185,70, 0.2)", colorIndex: 2 },
    { name: "energy.grid", dataKey: "loadGridData", fillColor: "rgba(229, 231, 235, 0.2)", colorIndex: 3 },
  ],
  2: [
    { name: "energy.Home", dataKey: "destPhotoLoad", fillColor: "rgba(56, 189, 248,0.2)", colorIndex: 0 },
    { name: "energy.battery", dataKey: "destPhotoBattery", fillColor: "rgba(94,185,70, 0.2)", colorIndex: 2 },
    { name: "energy.grid", dataKey: "destPhotoGrid", fillColor: "rgba(229, 231, 235, 0.2)", colorIndex: 3 },
  ],
  3: [
    { name: "energy.Home", dataKey: "destBatteryLoad", fillColor: "rgba(56, 189, 248,0.2)", colorIndex: 0 },
    { name: "energy.grid", dataKey: "destBatteryGrid", fillColor: "rgba(229, 231, 235, 0.2)", colorIndex: 3 },
    // { name: "energy.grid", dataKey: "batterySumData", fillColor: "rgba(229, 231, 235, 0.2)", colorIndex: 3 },
    {
      name: "energy.photovoltaic",
      dataKey: "batteryPhotovoltaicData",
      fillColor: "rgba(253, 224, 71, 0.2)",
      colorIndex: 1,
    },
    { name: "energy.grid", dataKey: "batteryGridData", fillColor: "rgba(229, 231, 235, 0.2)", colorIndex: 3 },
  ],
  4: [
    {
      name: "energy.photovoltaic",
      dataKey: "gridPhotovoltaicData",
      fillColor: "rgba(253, 224, 71, 0.2)",
      colorIndex: 1,
    },
    { name: "energy.Home", dataKey: "destGridLoad", fillColor: "rgba(56, 189, 248,0.2)", colorIndex: 0 },
    // { name: "energy.battery", dataKey: "gridSumData", fillColor: "rgba(94,185,70, 0.2)", colorIndex: 2 },
    { name: "energy.battery", dataKey: "gridBatteryData", fillColor: "rgba(94,185,70, 0.2)", colorIndex: 2 },
    { name: "energy.battery", dataKey: "destGridBattery", fillColor: "rgba(94,185,70, 0.2)", colorIndex: 2 },
  ],
};

const exportLabels: { [key: number]: string } = {
  1: "SourceLabel",
  2: "DestinationsLabel",
  3: "BatteryChargeLabel",
  4: "GridExportLabel",
};

const formattMonthsDateLong = (dateTs: Date, language: string, withoutDay: boolean) => {
  // energy graph date formatter
  const date = new Date(dateTs);

  const day = date.getUTCDate();
  const month = date.getUTCMonth();

  // Array of month abbreviations in English
  const monthNamesEng = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Array of month abbreviations in Slovenian
  const monthNamesSlo = [
    "Januar",
    "Februar",
    "Marec",
    "April",
    "Maj",
    "Junij",
    "Julij",
    "Avgust",
    "September",
    "Oktober",
    "November",
    "December",
  ];

  const monthNamesCro = [
    "Siječanj",
    "Veljača",
    "Ožujak",
    "Travanj",
    "Svibanj",
    "Lipanj",
    "Srpanj",
    "Kolovoz",
    "Rujan",
    "Listopad",
    "Studeni",
    "Prosinac",
  ];

  const monthNamesGer = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  let monthNames;
  switch (language) {
    case "en":
      monthNames = monthNamesEng;
      break;
    case "sl":
      monthNames = monthNamesSlo;
      break;
    case "hr":
      monthNames = monthNamesCro;
      break;
    case "de":
      monthNames = monthNamesGer;
      break;
    default:
      monthNames = monthNamesEng;
      break;
  }
  if (withoutDay) return `${monthNames[month]}`;
  return `${day}. ${monthNames[month]}`;
};

const formattMonthsDate = (dateTs: Date, language: string, withoutDay: boolean) => {
  // energy graph date formatter
  const date = new Date(dateTs);

  const day = date.getUTCDate();
  const month = date.getUTCMonth();

  const monthNamesEng = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthNamesSlo = ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"];
  const monthNamesCro = ["Sij", "Velj", "Ožu", "Tra", "Svi", "Lip", "Srp", "Kol", "Ruj", "Lis", "Stu", "Pro"];
  const monthNamesGerAbbreviated = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"];

  let monthNames;
  switch (language) {
    case "en":
      monthNames = monthNamesEng;
      break;
    case "sl":
      monthNames = monthNamesSlo;
      break;
    case "hr":
      monthNames = monthNamesCro;
      break;
    case "de":
      monthNames = monthNamesGerAbbreviated;
      break;
    default:
      monthNames = monthNamesEng;
      break;
  }
  if (withoutDay) return `${monthNames[month]}`;
  return `${day} ${monthNames[month]}`;
};

const getYMinValue = (minMaxGraph: boolean, overallMax: number, boolMaxCharts: boolean, activeButton: number) => {
  let value;
  if (minMaxGraph) {
    if (activeButton === 1) {
      value = minChartMonth > overallMax ? minChartMonth * -1 : overallMax * -1;
    } else {
      value = overallMax * -1;
    }
  } else {
    if (boolMaxCharts) {
      if (activeButton === 1) {
        value = null; //minChartMonth >= overallMax ? null: minChartMonth * -1;
      } else {
        value = minChartVal * -1;
      }
    } else {
      value = null;
    }
  }
  return value;
};

const getYMaxValue = (minMaxGraph: boolean, overallMax: number, boolMaxCharts: boolean, activeButton: number) => {
  let value;
  if (minMaxGraph) {
    if (activeButton === 1) {
      value = minChartMonth < overallMax ? overallMax : minChartMonth;
    } else {
      value = overallMax;
    }
  } else {
    if (boolMaxCharts) {
      if (activeButton === 1) {
        value = minChartMonth < overallMax ? null : minChartMonth;
      } else {
        value = minChartVal;
      }
    } else {
      value = null;
    }
  }
  return value;
};

const formatDate = (date: Date, format: string) => {
  // energy graph date formatter
  const offsetInMilliseconds = date.getTimezoneOffset() * 60 * 1000;
  const utcDate = new Date(date.getTime() + offsetInMilliseconds);

  if (format === "DD.MM") {
    const day = String(utcDate.getDate()).padStart(2, "0");
    const month = String(utcDate.getMonth() + 1).padStart(2, "0");
    return `${day}.${month}`;
  } else if (format === "HH:mm") {
    const hours = String(utcDate.getHours()).padStart(2, "0");
    const minutes = String(utcDate.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  }
  return "";
};

const getTranslateKey = (name: string, plantType: IDevicePlantType | undefined): string => {
  if (name !== "energy.photovoltaic") {
    return name;
  }

  switch (plantType) {
    case "hydroelectric":
      return "energy.hydroelectric";
    case "biomass":
      return "energy.biomass";
    case "wind":
      return "energy.wind";
    default:
      return name;
  }
};

export const generateOptions = (
  selectedIcon: number,
  jsonData: any,
  splitEnergy: boolean,
  minMaxGraph: boolean,
  t: (key: string) => string,
  activeButton: number,
  currentLanguage: string,
  currentDate: Date,
  feedInPriorStat: boolean | undefined,
  plantType: IDevicePlantType | undefined,
) => {
  // Process the jsonData based on the selectedIcon
  const processedData = jsonData;
  const colors = ["#38bdf8", "#fde047", "#5EB946", "#e5e7eb"];
  const getSeriesData = () => {
    if (jsonData === null) {
      return [];
    }
    let config;
    if (splitEnergy) {
      if (selectedIcon === 2) {
        // in case of feed in priority change order
        if (feedInPriorStat) {
          config = [
            { name: "energy.Home", dataKey: "destPhotoLoad", fillColor: "rgba(56, 189, 248,0.2)", colorIndex: 0 },
            { name: "energy.grid", dataKey: "destPhotoGrid", fillColor: "rgba(229, 231, 235, 0.2)", colorIndex: 3 },
            { name: "energy.battery", dataKey: "destPhotoBattery", fillColor: "rgba(94,185,70, 0.2)", colorIndex: 2 },
          ];
        } else {
          config = iconConfig[selectedIcon];
        }
      } else {
        config = iconConfig[selectedIcon];
      }
    } else {
      config = iconConfig[0].filter((item) => item.id === selectedIcon);
    }
    if (!config || config.length === 0) {
      return [];
    }

    return config.map(({ name, dataKey, fillColor, colorIndex }, i) => ({
      type: activeButton === 0 ? "areaspline" : "column",
      animation: false,
      name: t(getTranslateKey(name, plantType)),
      data: processedData[dataKey],
      fillColor,
      color: colors[colorIndex],
      marker: {
        symbol: "circle",
      },
      // so this is because we reversed stacking of charts ->  reversedStacks: false, when grid is value 0 its show on top
      // so we put z index that border hides
      zIndex: config.length - i, //
    }));
  };

  const getIntervalSoftExtremes = () => {
    let startOfPeriodTs = undefined;
    let endOfPeriodTs = undefined;

    const utcDate = new UTCDate(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

    if (activeButton === 0) {
      startOfPeriodTs = startOfDay(utcDate).getTime();
      endOfPeriodTs = sub(endOfDay(utcDate), { minutes: 10 }).getTime();
    } else if (activeButton === 1) {
      startOfPeriodTs = startOfMonth(utcDate).getTime();
      endOfPeriodTs = sub(endOfMonth(utcDate), { days: 1 }).getTime(); // hack to make it look nice on the chart
    } else if (activeButton === 2) {
      startOfPeriodTs = startOfYear(utcDate).getTime();
      endOfPeriodTs = sub(endOfYear(utcDate), {
        months: 1,
      }).getTime(); // hack to make it look nice on the chart
    }

    return [startOfPeriodTs, endOfPeriodTs];
  };
  const [softMin, softMax] = getIntervalSoftExtremes();

  const type = activeButton === 0 ? "daily" : activeButton === 1 ? "monthly" : activeButton === 2 ? "yearly" : "lifetime";
  const period =
    activeButton === 0
      ? format(currentDate, "dd.MM.yyyy")
      : activeButton === 1
        ? format(currentDate, "MM.yyyy")
        : activeButton === 2
          ? format(currentDate, "yyyy")
          : "lifetime";

  const options: any = {
    chart: {
      backgroundColor: "#1f2937",
      height: activeButton === 3 ? "525px" : "455px",
      zoomType: "x",
      displayErrors: true,
      animation: false,
      events: {
        // redraw(this: CustomChart) {
        //   this.customLabels = this.customLabels || [];
        //   this.customLabels.forEach((label: Highcharts.SVGElement) => label.destroy());
        //   let overZero = false;
        //   let underZero = false;
        //   // Go through each series and each point
        //   this.series.forEach((series) => {
        //     series.points.forEach((point) => {
        //       // Check if y-value exists
        //       if (point.y !== undefined && this.customLabels !== undefined) {
        //         // Check for conditions to display Export/Import
        //         if (point.y > 0) {
        //           // Add 'Export' label
        //           overZero = true;
        //         } else if (point.y < 0) {
        //           // Add 'Import' label
        //           underZero = true;
        //         }
        //       }
        //     });
        //   });
        //   if (overZero) {
        //     const textToDisplayOver =
        //       splitEnergy === true
        //         ? selectedIcon === 1
        //           ? t("energy.sourceChart")
        //           : t("energy.loadChart")
        //         : selectedIcon === 1
        //         ? t("energy.source")
        //         : t("energy.destination");
        //     const exportLabel = this.renderer
        //       .text(textToDisplayOver, this.chartWidth - 100, 50)
        //       .css({
        //         color: "#6B7280",
        //         fontSize: "16px",
        //         fontFamily: "inherit",
        //         fontWeight: "bold",
        //       })
        //       .add();
        //     this.customLabels.push(exportLabel);
        //   }
        //   if (underZero) {
        //     const textToDisplayBellow = splitEnergy === true ? t("energy.sourceChart") : t("energy.source");
        //     const importLabel = this.renderer
        //       .text(textToDisplayBellow, this.chartWidth - 100, this.chartHeight - 50)
        //       .css({
        //         color: "#6B7280",
        //         fontSize: "16px",
        //         fontFamily: "inherit",
        //         fontWeight: "bold",
        //       })
        //       .add();
        //     this.customLabels.push(importLabel);
        //   }
        // },
      },
    },

    title: {
      text: "",
      style: {
        color: "#ffffff",
      },
    },
    legend: {
      enabled: false, // Disable the legend
      itemStyle: {
        color: "#ffffff",
      },
      itemHoverStyle: {
        color: "#ffffff",
      },
    },
    xAxis: {
      type: "datetime",
      // tickPositioner: function (this: any) {
      // return positions;
      // },
      softMin,
      softMax,

      labels: {
        style: {
          color: "#ffffff",
          fontFamily: "inherit",
        },
        formatter: function (this: any) {
          if (activeButton === 1) {
            return formattMonthsDate(this.value, currentLanguage, false);
          } else if (activeButton === 2) {
            return formattMonthsDate(this.value, currentLanguage, true);
          } else if (activeButton === 3) {
            const date = new Date(this.value);
            return date.getFullYear().toString();
          }
          // in case 00:00 for daily, we show Date, thats the default behavior
          if (Highcharts.dateFormat("%H:%M", this.value) === "00:00") {
            return formattMonthsDate(this.value, currentLanguage, false);
          }
          return Highcharts.dateFormat("%H:%M", this.value);
        },
      },
    },
    yAxis: {
      opposite: true,
      reversedStacks: false,
      labels: {
        format: "{value}",
        enabled: true,
        style: {
          color: "#ffffff",
          fontFamily: "inherit",
        },
      },
      title: {
        text: activeButton === 0 ? " kW" : " kWh",
        align: "middle",
        offset: 0,
        rotation: 270,
        x: 10,
        style: {
          color: "#ffffff",
          fontFamily: "inherit",
        },
      },
      min: getYMinValue(minMaxGraph, jsonData?.overallMax, jsonData?.boolMinCharts[selectedIcon - 1], activeButton),
      // min: minMaxGraph
      //   ? jsonData.overallMax * -1
      //   : jsonData?.boolMinCharts[selectedIcon - 1]
      //   ? (activeButton === 1 ? minChartMonth : minChartVal) * -1
      //   : null,
      max: getYMaxValue(minMaxGraph, jsonData?.overallMax, jsonData?.boolMaxCharts[selectedIcon - 1], activeButton),
      // minMaxGraph
      // ? jsonData.overallMax
      // : jsonData?.boolMaxCharts[selectedIcon - 1]
      // ? activeButton === 1
      //   ? minChartMonth
      //   : minChartVal
      // : null
      showLastLabel: true,
      endOnTick: true,
      gridLineWidth: 0.08,
      plotLines: [
        {
          color: "#6B7280", // Color value
          dashStyle: "Dot", // Style of the plot line. Default to solid
          value: 0, // Value of where the line will appear
          width: 3, // Width of the line
        },
      ],
      // tickAmount: minMaxGraph ? null : 6,
    },
    tooltip: {
      shared: true,
      outside: true,
      // split: true,
      crosshairs: true,
      xDateFormat: activeButton === 1 ? "%d.%m" : "%H:%M",
      // xDateFormat: activeButton === 1 ? "%d %b" : "%H:%M",
      // positioner: function (
      //   this: Tooltip,
      //   boxWidth: number,
      //   boxHeight: number,
      //   point: { plotX: number; plotY: number }
      // ) {
      //   var chart = this.chart;
      //   var tooltipX, tooltipY;
      //   tooltipX = Math.min(chart.plotWidth - boxWidth, point.plotX);
      //   // If point is in the first half of the chart height
      //   if (point.plotY < chart.plotHeight / 2) {
      //     // Draw tooltip at the bottom
      //     tooltipY = chart.plotHeight - boxHeight;
      //   } else {
      //     // Draw tooltip at the top
      //     tooltipY = 0;
      //   }

      //   return { x: tooltipX, y: tooltipY };
      // },
      headerFormat: splitEnergy
        ? '<span style="color:black">{point.key}</span><br/><span style="color:black;font-weight: bold;">' +
          t("chart.total") +
          ": {point.total} kW</span><br/>"
        : "<span>{point.key}</span><br/>",
      formatter: function (this: any): string {
        const xDateFormat = activeButton === 1 ? "DD.MM" : "HH:mm";
        let formattedDate = formatDate(new Date(this.x), xDateFormat);
        let formatedValue = activeButton === 0 ? " kW" : " kWh";
        let sumValueY = 0;
        if (splitEnergy) {
          const sumValueData = processedData[iconConfig[0][selectedIcon - 1].dataKey].find(
            ([xValue, _]: [number, any]) => xValue === this.x,
          );
          sumValueY = sumValueData ? sumValueData[1] : null;
        }
        // round to 1 decimal tooltip yearly
        if (activeButton === 2 || activeButton === 3) sumValueY = Math.round(sumValueY * 10) / 10;
        // when net value(splitEnergy) on monthly use 2 decimals, otherwise as is 3 decimals
        if (activeButton === 1 && !splitEnergy) sumValueY = Math.round(sumValueY * 100) / 100;
        let positiveArray: string[] = [];
        let negativeArray: string[] = [];
        let sumPositive = 0;
        let sumNegative = 0;
        let dailyTooltip = "";
        for (let index = 0; index < this.points.length; index++) {
          const element = this.points[index];
          if (element.y === undefined || element.y === 0) {
            continue;
          }
          // const absY = Math.abs(element.y);
          let absY = splitEnergy && activeButton === 1 ? Math.abs(element.y) : element.y;
          // round to 1 decimal tooltip yearly
          if (activeButton === 2 || activeButton === 3) absY = Math.round(absY * 10) / 10;
          // when net value(splitEnergy) on monthly use 2 decimals, otherwise as is 3 decimals
          if (activeButton === 1 && !splitEnergy) absY = Math.round(absY * 100) / 100;
          const seriesDes = `<span style="color:black">${element.series.name}</span>: <b>${absY}${formatedValue}</b>`;
          dailyTooltip = dailyTooltip + `<span style="color:black">${element.series.name}</span>: <b>${absY}${formatedValue}</b><br/>`;
          if (element.y >= 0) {
            positiveArray.push(seriesDes);
            sumPositive += absY;
          } else {
            negativeArray.push(seriesDes);
            sumNegative += absY;
          }
        }
        const yPostitiveTxt = selectedIcon === 1 ? t("energy.sourceChart") : t("energy.loadChart");
        const yNegativeTxt = selectedIcon === 1 ? t("energy.loadChart") : t("energy.sourceChart");
        const positiveHtml =
          positiveArray.length > 0
            ? `<br><span style="color:black;font-weight: bold;">${yPostitiveTxt}: ${sumPositive.toFixed(2)}${formatedValue}</span></br>` +
              positiveArray.join("<br/>")
            : "";
        const negativeHtml =
          negativeArray.length > 0
            ? `<br><span style="color:black;font-weight: bold;">${yNegativeTxt}: ${sumNegative.toFixed(2)}${formatedValue}</span></br>` +
              negativeArray.join("<br/>")
            : "";
        if (activeButton === 1) {
          formattedDate = formattMonthsDateLong(this.x, currentLanguage, false);
        } else if (activeButton === 2) {
          formattedDate = formattMonthsDateLong(this.x, currentLanguage, true);
        } else if (activeButton === 3) {
          const date = new Date(this.x);
          formattedDate = date.getFullYear().toString();
        }
        let res = splitEnergy
          ? `<span style="color:black">${formattedDate}</span><br/><span style="color:black;font-weight: bold;">` +
            t("chart.total") +
            `: ${sumValueY}${formatedValue}</span><br/>`
          : `<span>${formattedDate}</span><br/> `;
        if (activeButton !== 0 && splitEnergy) {
          return res + positiveHtml + '<br style="line-height: 5px" />' + negativeHtml;
        } else {
          return res + dailyTooltip;
        }
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
        fillOpacity: 0.1,
        pointRange: activeButton === 1 ? 24 * 3_600_000 : activeButton === 2 ? 30 * 24 * 3_600_000 : undefined,
      },
      areaspline: {
        stacking: "normal",
      },
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: getSeriesData(),
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
      filename: type + "_" + period + "_" + (exportLabels[selectedIcon] ? t(`energy.energy${exportLabels[selectedIcon]}`) : "N/A"),
    },
    noData: {
      useHTML: true,
      style: {
        color: "#ffffff",
        fontSize: "1.5rem",
        fontWeight: "bold",
        fontFamily: "inherit",
      },
      position: {
        align: "center",
        verticalAlign: "middle",
      },
      attr: {
        zIndex: 10,
      },
      text: t("home.noData"),
    },
  };
  return options;
};

export const processData = (data: any, splitEnergy: boolean, activeButton: number) => {
  const gridData = [];
  const loadData = [];
  const photovoltaicData = [];
  const batteryData = [];
  const socData = [];
  const sumValues = [0, 0, 0, 0];
  const selfSufficiant = [];
  const destPhotoLoad = [];
  const destPhotoBattery = [];
  const destPhotoGrid = [];
  const destGridLoad = [];
  const destGridBattery = [];
  const destBatteryLoad = [];
  const destBatteryGrid = [];
  const allData = [];
  let dataKey;
  switch (activeButton) {
    case 0:
      dataKey = "history_data_5m";
      break;
    case 1:
      dataKey = "history_data_day";
      break;
    case 2:
      dataKey = "history_data_month";
      break;
    case 3:
      dataKey = "history_data_year";
      break;
    default:
      dataKey = "history_data_5m";
      break;
  }
  for (const timestamp in data[dataKey]) {
    const dateObj = data?.date?.$date || data?.date || timestamp;
    let baseTime = typeof dateObj === "object" && dateObj.$numberLong ? parseInt(dateObj.$numberLong) : new Date(dateObj).getTime();
    let pointTime;
    if (dataKey === "history_data_5m") {
      pointTime = baseTime + parseInt(timestamp) * 60 * 1000; // minutes
    } else if (dataKey === "history_data_day") {
      pointTime = addDays(baseTime, parseInt(timestamp) - 1).getTime();
    } else if (dataKey === "history_data_month") {
      // year
      baseTime = addDays(baseTime, 1).getTime();
      pointTime = addMonths(baseTime, parseInt(timestamp) - 1).getTime();
    } else {
      // lifetime
      pointTime = new Date(`${timestamp}-01-01`).getTime();
    }
    const point = data[dataKey][timestamp];
    if (dataKey === "history_data_5m") {
      gridData.push([pointTime, point.grid]);
      loadData.push([pointTime, point.load]);
      // we cut small negative surges in photovoltaic data
      if (point.photovoltaic < 0) point.photovoltaic = 0;
      photovoltaicData.push([pointTime, point.photovoltaic]);
      batteryData.push([pointTime, point.battery]);
      socData.push([pointTime, point.soc]);
      selfSufficiant.push([pointTime, point.self_sufficiency]);

      allData.push(point.grid);
      allData.push(point.load);
      allData.push(point.photovoltaic);
      allData.push(point.battery);
    } else {
      gridData.push([pointTime, point.summary.energy.grid.combined]);
      loadData.push([pointTime, point.summary.energy.load.combined]);
      photovoltaicData.push([pointTime, point.summary.energy.photovoltaic.combined]);
      batteryData.push([pointTime, point.summary.energy.battery.combined]);
      // socData.push([pointTime, point.soc]);
      selfSufficiant.push([pointTime, point.summary.impact.self_sufficiency]);

      allData.push(point.summary.energy.grid.combined);
      allData.push(point.summary.energy.load.combined);
      allData.push(point.summary.energy.photovoltaic.combined);
      allData.push(point.summary.energy.battery.combined);
    }
  }
  const overallMin = Math.min(...allData);
  const overallMax = Math.max(...allData);
  let minCharts = [loadData, photovoltaicData, batteryData, gridData];
  let boolMinCharts = [false, false, false, false];
  let boolMaxCharts = [false, false, false, false];
  minCharts.forEach((data, index) => {
    let values = data.map((point) => point[1]);
    let min = Math.min(...values);
    let max = Math.max(...values);

    if (
      Math.abs(min) < (activeButton === 1 ? minChartMonth : minChartVal) &&
      Math.abs(max) < (activeButton === 1 ? minChartMonth : minChartVal)
    ) {
      boolMinCharts[index] = min < 0 ? true : false;
      boolMaxCharts[index] = max > 0 ? true : false;
    } else {
      boolMinCharts[index] = false;
      boolMaxCharts[index] = false;
    }
  });
  if (splitEnergy) {
    const loadBatteryData = [];
    const loadGridData = [];
    const loadPhotovoltaicData = [];
    const gridBatteryData = [];
    const gridPhotovoltaicData = [];
    const batteryGridData = [];
    const batteryPhotovoltaicData = [];
    const batterySumData = [];
    const gridSumData = [];

    for (const timestamp in data[dataKey]) {
      const dateObj = data?.date?.$date || data?.date || timestamp;
      let baseTime = typeof dateObj === "object" && dateObj.$numberLong ? parseInt(dateObj.$numberLong) : new Date(dateObj).getTime();
      let pointTime;
      if (dataKey === "history_data_5m") {
        pointTime = baseTime + parseInt(timestamp) * 60 * 1000; // minutes
      } else if (dataKey === "history_data_day") {
        pointTime = addDays(baseTime, parseInt(timestamp) - 1).getTime(); // * 24 * 60 * 60 * 1000; // days
      } else if (dataKey === "history_data_month") {
        // year
        baseTime = addDays(baseTime, 1).getTime();
        pointTime = addMonths(baseTime, parseInt(timestamp) - 1).getTime();
      } else {
        // lifetime
        pointTime = new Date(`${timestamp}-01-01`).getTime();
      }
      const point = activeButton === 0 ? data[dataKey][timestamp] : data[dataKey][timestamp].summary;
      let gridValue = 0;
      if (dataKey === "history_data_5m") {
        loadBatteryData.push([pointTime, point.energy_sources.load.battery.power]);
        loadGridData.push([pointTime, point.energy_sources.load.grid.power]);
        loadPhotovoltaicData.push([pointTime, point.energy_sources.load.photovoltaic.power]);
        gridBatteryData.push([pointTime, point.energy_sources.grid.battery.power]);
        gridPhotovoltaicData.push([pointTime, point.energy_sources.grid.photovoltaic.power]);
        batteryGridData.push([pointTime, point.energy_sources.battery.grid.power]);
        batteryPhotovoltaicData.push([pointTime, point.energy_sources.battery.photovoltaic.power]);
        destPhotoLoad.push([pointTime, point.energy_destinations.photovoltaic.load.power]);
        destPhotoBattery.push([pointTime, point.energy_destinations.photovoltaic.battery.power]);
        destPhotoGrid.push([pointTime, point.energy_destinations.photovoltaic.grid.power]);
        destGridLoad.push([pointTime, point.energy_destinations.grid.load.power]);
        destGridBattery.push([pointTime, point.energy_destinations.grid.battery.power]);
        destBatteryLoad.push([pointTime, point.energy_destinations.battery.load.power]);
        destBatteryGrid.push([pointTime, point.energy_destinations.battery.grid.power]);
        let powerValue = 0;
        if (point.energy_sources.battery.grid.power !== 0) {
          powerValue = point.energy_sources.battery.grid.power;
        } else if (point.energy_destinations.battery.grid.power !== 0) {
          powerValue = point.energy_destinations.battery.grid.power;
        }
        batterySumData.push([pointTime, powerValue]);

        if (point.energy_sources.grid.battery.power !== 0) {
          gridValue = point.energy_sources.grid.battery.power;
        } else if (point.energy_destinations.grid.battery.power !== 0) {
          gridValue = point.energy_destinations.grid.battery.power;
        }
        gridSumData.push([pointTime, gridValue]);
      } else {
        loadBatteryData.push([pointTime, point.energy_sources.load.battery.energy]);
        loadGridData.push([pointTime, point.energy_sources.load.grid.energy]);
        loadPhotovoltaicData.push([pointTime, point.energy_sources.load.photovoltaic.energy]);
        gridBatteryData.push([pointTime, -1 * point.energy_sources.grid.battery.energy]);
        gridPhotovoltaicData.push([pointTime, -1 * point.energy_sources.grid.photovoltaic.energy]);
        batteryGridData.push([pointTime, -1 * point.energy_sources.battery.grid.energy]);
        batteryPhotovoltaicData.push([pointTime, -1 * point.energy_sources.battery.photovoltaic.energy]);
        destPhotoLoad.push([pointTime, point.energy_destinations.photovoltaic.load.energy]);
        destPhotoBattery.push([pointTime, point.energy_destinations.photovoltaic.battery.energy]);
        destPhotoGrid.push([pointTime, point.energy_destinations.photovoltaic.grid.energy]);
        destGridLoad.push([pointTime, point.energy_destinations.grid.load.energy]);
        destGridBattery.push([pointTime, point.energy_destinations.grid.battery.energy]);
        destBatteryLoad.push([pointTime, point.energy_destinations.battery.load.energy]);
        destBatteryGrid.push([pointTime, point.energy_destinations.battery.grid.energy]);
        let powerValue = 0;
        if (point.energy_sources.battery.grid.energy !== 0) {
          powerValue = point.energy_sources.battery.grid.energy;
        } else if (point.energy_destinations.battery.grid.energy !== 0) {
          powerValue = point.energy_destinations.battery.grid.energy;
        }
        batterySumData.push([pointTime, powerValue]);

        if (point.energy_sources.grid.battery.energy !== 0) {
          gridValue = point.energy_sources.grid.battery.energy;
        } else if (point.energy_destinations.grid.battery.energy !== 0) {
          gridValue = point.energy_destinations.grid.battery.energy;
        }
        gridSumData.push([pointTime, gridValue]);
      }
    }
    return {
      gridData,
      loadData,
      photovoltaicData,
      batteryData,
      sumValues,
      socData,
      loadBatteryData,
      loadGridData,
      loadPhotovoltaicData,
      gridBatteryData,
      gridPhotovoltaicData,
      batteryGridData,
      batteryPhotovoltaicData,
      selfSufficiant,
      destPhotoLoad,
      destPhotoBattery,
      destPhotoGrid,
      destGridLoad,
      destGridBattery,
      destBatteryLoad,
      destBatteryGrid,
      overallMin,
      overallMax,
      boolMinCharts,
      boolMaxCharts,
      batterySumData,
      gridSumData,
    };
  } else {
    return {
      gridData,
      loadData,
      photovoltaicData,
      batteryData,
      sumValues,
      socData,
      selfSufficiant,
      overallMin,
      overallMax,
      boolMinCharts,
      boolMaxCharts,
    };
  }
};
