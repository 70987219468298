import moment from "moment-timezone";
import { Button } from "./Button/Button";
import { IChargeGroupStation, IChargeGroupStationStatus } from "../../../../types/ChargeGroup";
import { Loading } from "../../../../decorators/loading/Loading";
import { RestEnv } from "../../../../services/restService/restUtils";
import { useCallback } from "react";
import { useGetData } from "../../../../hooks/useGetData";
import { Value } from "./Value/Value";
import {
  mdiAccount,
  mdiClockOutline,
  mdiEvStation,
  mdiLockOpenVariantOutline,
  mdiPlayCircleOutline,
  mdiPowerPlugOutline,
  mdiRefresh,
  mdiRestart,
  mdiStateMachine,
  mdiStopCircleOutline,
} from "@mdi/js";

type IResponse = {
  status: string;
  data: IChargeGroupStationStatus;
};

export function StationContent(props: { station: IChargeGroupStation }) {
  const { data, isLoading } = useGetData<IChargeGroupStationStatus>({
    request: {
      env: RestEnv.DATA,
      path: `/justcharge/api/v1/stations/status/id/${props.station.id}/`,
    },
    mapData: useCallback((data: IResponse) => data.data, []),
  });

  if (isLoading) {
    return <Loading text="Fetching station status, please wait ..." iconPosition="left" hideShadow />;
  }

  const def = getStatusDescriptionObj(data?.charge_status || "Unknown");
  return (
    <>
      <div className="grid w-full grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-1 2xl:grid-cols-2 3xl:grid-cols-3 4xl:grid-cols-4">
        <Value description="Status" value={def.description} icon={mdiStateMachine} valueClassName={def.valueClassName} />
        {data?.timestamp_start && (
          <Value
            description="Status change time"
            value={moment.tz(data?.timestamp_start, "Europe/Ljubljana").format("DD.MM.yyyy HH:mm:ss")}
            icon={mdiClockOutline}
          />
        )}
        {data?.plug && <Value description="Plug" value={data?.plug} icon={mdiPowerPlugOutline} unit="#" />}
        {data?.charge_user && <Value description="Charge user" value={data?.charge_user} icon={mdiAccount} />}
      </div>
      <div className="mt-4 flex w-full justify-end space-x-2 border-t border-brand-500/10 pt-2">
        <Button icon={mdiRestart}>Reset</Button>
        <Button icon={mdiEvStation}>Set charging profile</Button>
        <Button icon={mdiLockOpenVariantOutline}>Unlock connector</Button>
        <Button icon={mdiPlayCircleOutline}>Remote start</Button>
        <Button icon={mdiStopCircleOutline}>Remote stop</Button>
        <Button icon={mdiRefresh}>Refresh state</Button>
      </div>
    </>
  );
}

function getStatusDescriptionObj(status: string): { description: string; valueClassName: string } {
  switch (status) {
    case "X":
      return { description: "Scanned", valueClassName: "text-green-300" };
    case "S":
      return { description: "Scanned", valueClassName: "text-green-300" };
    case "D":
      return { description: "Car detected", valueClassName: "text-purple-400" };
    case "C":
      return { description: "Charging", valueClassName: "text-green-400" };
    case "E":
      return { description: "Suspended EV", valueClassName: "text-orange-400" };
    case "V":
      return { description: "Suspended EVSE", valueClassName: "text-red-400" };
    case "F":
      return { description: "Finishing ...", valueClassName: "text-orange-400" };
    default:
      return { description: "Unknown", valueClassName: "text-brand-500" };
  }
}
