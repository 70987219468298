import en from "./locales/en.json";
import i18n from "i18next";
import sl from "./locales/sl.json";
import { initReactI18next } from "react-i18next";

export const init = (defaultLanguage: string) => {
  i18n.use(initReactI18next).init({
    parseMissingKeyHandler: (key) => {
      console.warn("Missing translate key: " + key);
      return key;
    },
    lng: "en",
    resources: {
      en: {
        translations: en,
      },
      sl: {
        translations: sl,
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

  i18n.changeLanguage(defaultLanguage);
};

// i18n.languages = ["en", "ru"];

export default i18n;
