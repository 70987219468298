import {
  mdiNumeric1BoxOutline,
  mdiNumeric2BoxOutline,
  mdiNumeric3BoxOutline,
  mdiNumeric4BoxOutline,
  mdiNumeric5BoxOutline,
  mdiNumeric6BoxOutline,
  mdiNumeric7BoxOutline,
  mdiNumeric8BoxOutline,
  mdiNumeric9BoxOutline,
  mdiNumeric9PlusBoxOutline,
} from "@mdi/js";

export function parseValuesToArray(props: { startsWith: string; values: { [key: string]: any } }) {
  const Strings = Object.keys(props.values).filter((key) => key.startsWith(props.startsWith));
  const StringData = Strings.map((pvString) => {
    const index = parseInt(pvString.replace(props.startsWith, ""));
    return {
      index,
      value: props.values[pvString],
      iconPath: getIndexIconPath(index),
    };
  });

  return StringData.sort((a, b) => a.index - b.index);
}

export function getIndexIconPath(index: number) {
  switch (index) {
    case 1:
      return mdiNumeric1BoxOutline;
    case 2:
      return mdiNumeric2BoxOutline;
    case 3:
      return mdiNumeric3BoxOutline;
    case 4:
      return mdiNumeric4BoxOutline;
    case 5:
      return mdiNumeric5BoxOutline;
    case 6:
      return mdiNumeric6BoxOutline;
    case 7:
      return mdiNumeric7BoxOutline;
    case 8:
      return mdiNumeric8BoxOutline;
    case 9:
      return mdiNumeric9BoxOutline;
    default:
      return mdiNumeric9PlusBoxOutline;
  }
}
