import mqtt from "mqtt";

export class MQTTService {
  private readonly _options: mqtt.IClientOptions = {
    keepalive: 10,
    clientId: "web-client-alex-" + Math.random(),
    protocolId: "MQTT",
    protocolVersion: 4,
    clean: true,
    reconnectPeriod: 2_000,
    connectTimeout: 5_000,
  };
  private _client: mqtt.MqttClient | null = null;

  get client(): mqtt.MqttClient | null {
    return this._client;
  }

  connect(source: "ngenstar" | "powerbank", username: string, password: string): mqtt.MqttClient {
    const opts = { ...this._options, username, password };
    this._client = mqtt.connect((process.env.REACT_APP_MQTT_URL || "") + `/${source}/`, opts);
    return this._client;
  }

  close(): void {
    if (this._client?.connected) {
      this._client?.end(true);
    }
    this._client = null;
  }
}
