import { getDeviceCustomerAndMeasurementPoint } from "../_common/utils";
import { IDevice, IDeviceProjectPartner } from "../../../types/Device";
import { mdiBatteryHigh, mdiMeterElectricOutline, mdiPackageVariantClosed, mdiStarShootingOutline } from "@mdi/js";

export function getDevicesByCountry(devices: IDevice[]) {
  const ALL: { groupKey: string; groupName: string; devices: IDevice[] } = { groupKey: "all", groupName: "All countries", devices };

  const GROUPS = Object.values(
    devices.reduce(
      (acc, dev) => {
        if (!acc[dev.measuring_point.country.iso_code]) {
          acc[dev.measuring_point.country.iso_code] = {
            groupKey: dev.measuring_point.country.iso_code,
            groupName: dev.measuring_point.country.name,
            devices: [dev],
          };
        } else {
          acc[dev.measuring_point.country.iso_code].devices.push(dev);
        }

        return acc;
      },
      {} as {
        [countryKey: string]: {
          groupKey: string;
          groupName: string;
          devices: IDevice[];
        };
      },
    ),
  );

  return [ALL, ...GROUPS.sort((a, b) => a.groupName.localeCompare(b.groupName))];
}

export function getDevicesByProvisionGroup(devices: IDevice[]) {
  const ALL: { groupKey: string; groupName: string; devices: IDevice[] } = { groupKey: "all", groupName: "All devices", devices };

  const DEVICES_NEEDED_SUPPORT: { groupKey: string; groupName: string; devices: IDevice[] } = {
    groupKey: "devicesNeededSupport",
    groupName: "Devices needed support",
    devices: [],
  };

  const provisioned: { groupKey: string; groupName: string; devices: IDevice[] } = {
    groupKey: "provisioned",
    groupName: "Operating devices",
    devices: [],
  };
  const provisionedSupply: { groupKey: string; groupName: string; devices: IDevice[] } = {
    groupKey: "provisionedSupply",
    groupName: "Operating supply devices",
    devices: [],
  };
  const nonProvisioned: { groupKey: string; groupName: string; devices: IDevice[] } = {
    groupKey: "non-provisioned",
    groupName: "Ready for comissioning",
    devices: [],
  };

  devices.forEach((d) => {
    const { mp } = getDeviceCustomerAndMeasurementPoint(d);
    if (mp === "MM-provision") {
      nonProvisioned.devices.push(d);
    } else {
      const supplyDate = d.contract?.supply_start_date;
      if (supplyDate && supplyDate !== "0000-00-00") {
        provisionedSupply.devices.push(d);

        if (d.online !== "yes") {
          DEVICES_NEEDED_SUPPORT.devices.push(d);
        }
      } else {
        provisioned.devices.push(d);
      }
    }
  });

  return [ALL, DEVICES_NEEDED_SUPPORT, provisionedSupply, provisioned, nonProvisioned];
}

export function getDevicesBySupplyGroup(devices: IDevice[]) {
  const ALL: { groupKey: string; groupName: string; devices: IDevice[] } = { groupKey: "all", groupName: "All supply groups", devices };

  const GROUPS = Object.values(
    devices.reduce(
      (acc, dev) => {
        if (!acc[dev.supply_group]) {
          acc[dev.supply_group] = {
            groupKey: dev.supply_group || "N/A",
            groupName: dev.supply_group || "Unknown",
            devices: [dev],
          };
        } else {
          acc[dev.supply_group].devices.push(dev);
        }

        return acc;
      },
      {} as {
        [countryKey: string]: {
          groupKey: string;
          groupName: string;
          devices: IDevice[];
        };
      },
    ),
  );

  return [ALL, ...GROUPS.sort((a, b) => a.groupName.localeCompare(b.groupName))];
}

export function getDevicesByType(devices: IDevice[]) {
  const ALL: { groupKey: string; groupName: string; devices: IDevice[] } = { groupKey: "all", groupName: "All device types", devices };

  const GROUPS = Object.values(
    devices.reduce(
      (acc, dev) => {
        if (!acc[dev.device.type]) {
          acc[dev.device.type] = {
            groupKey: dev.device.type || "N/A",
            groupName: getDeviceTypeDefinition(dev).name || "Unknown",
            devices: [dev],
          };
        } else {
          acc[dev.device.type].devices.push(dev);
        }

        return acc;
      },
      {} as {
        [countryKey: string]: {
          groupKey: string;
          groupName: string;
          devices: IDevice[];
        };
      },
    ),
  );

  return [ALL, ...GROUPS.sort((a, b) => a.groupName.localeCompare(b.groupName))];
}

export function getDevicesByPartner(devices: IDevice[]) {
  type IPartnerDevices = {
    partnerKey: string;
    partnerName: string;
    devices: IDevice[];
  };

  const ALL: IPartnerDevices = { partnerKey: "all", partnerName: "All partners", devices };
  const UNDEFINED: IPartnerDevices = { partnerKey: "undefined", partnerName: "Undefined partner", devices: [] };

  const GROUPS = Object.values(
    devices.reduce(
      (acc, device) => {
        const projPartner: IDeviceProjectPartner = device.project_partner || {
          id: -1,
          name: "N/A",
          email: "N/A",
        };

        if (projPartner.id === -1) {
          UNDEFINED.devices.push(device);
          return acc;
        }

        if (!acc[projPartner.id]) {
          acc[projPartner.id] = {
            partnerKey: projPartner.id.toString(),
            partnerName: projPartner.name,
            devices: [device],
          };
        } else {
          acc[projPartner.id].devices.push(device);
        }

        return acc;
      },
      {} as {
        [countryKey: string]: IPartnerDevices;
      },
    ),
  );

  return [ALL, ...(UNDEFINED.devices.length > 0 ? [UNDEFINED] : []), ...GROUPS.sort((a, b) => a.partnerName.localeCompare(b.partnerName))];
}

export function getDeviceTypeDefinition(device: IDevice): { name: string; icon?: string; classNameColor?: string } {
  return deviceTypesDefinitions[device.device.type] || device.device.type;
}

const deviceTypesDefinitions = {
  ngenstar: { name: "NGEN Star", icon: mdiStarShootingOutline, classNameColor: "text-yellow-500" },
  powerbank: { name: "Powerbank", icon: mdiBatteryHigh, classNameColor: "text-green-500" },
  "smart-meter": { name: "Smart meter", icon: mdiMeterElectricOutline, classNameColor: "text-blue-500" },
  megapack: { name: "Megapack", icon: mdiPackageVariantClosed, classNameColor: "text-purple-500" },
};
