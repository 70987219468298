import moment from "moment";
import StatusContainer from "../../../../../_common/StatusContainer";
import StatusValue from "../../../../../_common/StatusValue";
import { getStateContainerClass } from "../../utils";
import { useId, useState } from "react";
import { useMqttProperty } from "../../../../../../../hooks/useMqttClient";
import {
  mdiChip,
  mdiCog,
  mdiEvPlugType1,
  mdiListStatus,
  mdiNumeric,
  mdiNumeric1BoxOutline,
  mdiNumeric2BoxOutline,
  mdiNumeric3BoxOutline,
  mdiRemote,
  mdiSigma,
  mdiToggleSwitch,
  mdiToggleSwitchOffOutline,
} from "@mdi/js";

export default function InverterState() {
  const componentId = useId();
  const [state, setState] = useState<{
    isLoading: boolean;
    workMode: string;
    workModeValue: number | undefined;
    inverState: string;
    remoteControl: string;
    remoteControlValue: number | undefined;
    remoteControlActivePower: number | undefined;
    phasePower: {
      L1: number | undefined;
      L2: number | undefined;
      L3: number | undefined;
      _total: number | undefined;
    };
    systemState: string;
    systemStateValue: number | undefined;
    balanceLogic: string;
    balanceLogicValue: number | undefined;
    balanceLoad: string;
    balanceLoadValue: number | undefined;
    gridCode: string;
    gridCodeValue: number | undefined;
    dateTime: string;
  }>({
    isLoading: true,
    workMode: "N/A",
    workModeValue: undefined,
    inverState: "N/A",
    remoteControl: "N/A",
    remoteControlValue: undefined,
    remoteControlActivePower: undefined,
    phasePower: {
      L1: undefined,
      L2: undefined,
      L3: undefined,
      _total: undefined,
    },
    dateTime: "N/A",
    systemState: "N/A",
    systemStateValue: undefined,
    balanceLogic: "N/A",
    balanceLogicValue: undefined,
    balanceLoad: "N/A",
    balanceLoadValue: undefined,
    gridCode: "N/A",
    gridCodeValue: undefined,
  });

  useMqttProperty(
    "inverter",
    componentId,
    10_000,
    (data: {
      power: {
        L1: number;
        L2: number;
        L3: number;
      };
      state: number;
      work_mode: number;
      remote_control: number;
      remote_control_active_power: number;
      system_state: number;
      balance_logic: number;
      balance_load: number;
      grid_code: number;
      metadata: {
        utc_timestamp: number;
      };
    }) => {
      setState({
        workMode: workModeMapping[data.work_mode] || "N/A",
        workModeValue: data.work_mode,
        inverState: inverterStateMapping[data.state] || "N/A",
        remoteControl: remoteControlMapping[data.remote_control] || "N/A",
        remoteControlValue: data.remote_control,
        remoteControlActivePower: data.remote_control_active_power,

        systemState: onOffMapping[data.system_state] || "N/A",
        systemStateValue: data.system_state,
        balanceLogic: onOffMapping[data.balance_logic] || "N/A",
        balanceLogicValue: data.balance_logic,
        balanceLoad: onOffMapping[data.balance_load] || "N/A",
        balanceLoadValue: data.balance_load,

        gridCode: gridCodes[data.grid_code] || "N/A",
        gridCodeValue: data.grid_code,

        phasePower: {
          L1: data.power.L1,
          L2: data.power.L2,
          L3: data.power.L3,
          _total:
            data.power.L1 != null || data.power.L2 != null || data.power.L3 != null
              ? (data.power.L1 || 0) + (data.power.L2 || 0) + (data.power.L3 || 0)
              : undefined,
        },

        dateTime:
          data.metadata.utc_timestamp != null
            ? moment.tz(data.metadata.utc_timestamp * 1000, "Europe/Ljubljana").format("DD.MM.yyyy HH:mm:ss")
            : "N/A",
        isLoading: false,
      });
    }
  );

  return (
    <StatusContainer title="Inverter state" dateTime={state.dateTime} icon={mdiChip} className={getStateContainerClass("inverter")}>
      <StatusValue
        isLoading={state.isLoading}
        value={state.systemState}
        description="System state"
        icon={state.systemStateValue === 0 ? mdiToggleSwitchOffOutline : mdiToggleSwitch}
        editable={{
          type: "comboBoxInput",
          dataValue: state.systemStateValue,
          dataKey: "system-state",
          options: Object.entries(onOffMapping).map(([key, value]) => ({ key, value: value.toUpperCase() })),
          warning:
            "Under no circumstances should you modify this value unless you possess a complete understanding of the system. Changing this value makes you fully responsible!",
        }}
      />
      <StatusValue
        isLoading={state.isLoading}
        value={state.gridCode}
        description="Grid code"
        icon={mdiEvPlugType1}
        editable={{
          type: "comboBoxInput",
          dataValue: state.gridCodeValue,
          dataKey: "grid-code",
          options: Object.entries(gridCodes)
            .map(([key, value]) => ({ key, value: value.toUpperCase() }))
            .sort((a, b) => a.value.localeCompare(b.value)),
          warning:
            "Under no circumstances should you modify this value unless you possess a complete understanding of the system. Changing this value makes you fully responsible!",
        }}
      />
      <StatusValue
        isLoading={state.isLoading}
        value={state.balanceLogic}
        description="Balance logic"
        icon={state.balanceLogicValue === 0 ? mdiToggleSwitchOffOutline : mdiToggleSwitch}
        editable={{
          type: "comboBoxInput",
          dataValue: state.balanceLogicValue,
          dataKey: "balance-logic",
          options: Object.entries(onOffMapping).map(([key, value]) => ({ key, value: value.toUpperCase() })),
          warning:
            "Under no circumstances should you modify this value unless you possess a complete understanding of the system. Changing this value makes you fully responsible!",
        }}
      />
      <StatusValue
        isLoading={state.isLoading}
        value={state.balanceLoad}
        description="Balance load"
        icon={state.balanceLoadValue === 0 ? mdiToggleSwitchOffOutline : mdiToggleSwitch}
        editable={{
          type: "comboBoxInput",
          dataValue: state.balanceLoadValue,
          dataKey: "balance-load",
          options: Object.entries(onOffMapping).map(([key, value]) => ({ key, value: value.toUpperCase() })),
          warning:
            "Under no circumstances should you modify this value unless you possess a complete understanding of the system. Changing this value makes you fully responsible!",
        }}
      />
      <StatusValue
        isLoading={state.isLoading}
        value={state.workMode}
        description="Work mode"
        icon={mdiCog}
        editable={{
          type: "comboBoxInput",
          dataValue: state.workModeValue,
          dataKey: "work-mode",
          options: Object.entries(workModeMapping)
            .map(([key, value]) => ({ key, value: value.toUpperCase() }))
            .filter((x) => ["1", "2", "4", "5", "6", "7"].includes(x.key)),
        }}
      />
      <StatusValue isLoading={state.isLoading} value={state.inverState} description="Inverter state" icon={mdiListStatus} />
      <StatusValue
        isLoading={state.isLoading}
        value={state.remoteControl}
        description="Remote control"
        icon={mdiRemote}
        editable={{
          type: "comboBoxInput",
          dataValue: state.remoteControlValue,
          dataKey: "remote-control",
          options: Object.entries(remoteControlMapping).map(([key, value]) => ({ key, value: value.toUpperCase() })),
        }}
      />
      <StatusValue
        isLoading={state.isLoading}
        value={state.remoteControlActivePower}
        unit="W"
        description="Remote control active power"
        icon={mdiNumeric}
        editable={{
          type: "numberInput",
          dataValue: state.remoteControlActivePower,
          dataKey: "active-power",
          validation: {
            min: {
              value: -100_000,
              message: "Value must be bigger than -100000!",
            },
            max: {
              value: 100_000,
              message: "Value must be smaller than 100000!",
            },
          },
        }}
      />
      <StatusValue
        isLoading={state.isLoading}
        value={state.phasePower.L1}
        description="Inverter phase L1 power"
        icon={mdiNumeric1BoxOutline}
        unit="W"
      />
      <StatusValue
        isLoading={state.isLoading}
        value={state.phasePower.L2}
        description="Inverter phase L2 power"
        icon={mdiNumeric2BoxOutline}
        unit="W"
      />
      <StatusValue
        isLoading={state.isLoading}
        value={state.phasePower.L3}
        description="Inverter phase L3 power"
        icon={mdiNumeric3BoxOutline}
        unit="W"
      />
      <StatusValue
        isLoading={state.isLoading}
        value={state.phasePower._total}
        description="Total inverter power"
        icon={mdiSigma}
        unit="W"
      />
    </StatusContainer>
  );
}

const workModeMapping: { [key: number]: string } = {
  0: "self use [ 0 ]",
  1: "H3: Feed In, H3 PRO: Self Use [ 1 ]",
  2: "backup [ 2 ]",
  3: "power station [ 3 ]",
  // 4: "peak shaving [ 4 ]",
  4: "ngen mode [ 4 ]",
  5: "ngen mode - PRO [ 5 ]",
  6: "Force charge",
  7: "Force discharge",
};

export const inverterStateMapping: { [key: number]: string } = {
  0: "waiting [ 0 ]",
  1: "self check [ 1 ]",
  2: "on grid [ 2 ]",
  3: "EPS [ 3 ]",
  4: "fault [ 4 ]",
  5: "permanent fault [ 5 ]",
  6: "self test [ 6 ]",
  7: "flash[ 7 ]",
  8: "idle state [ 8 ]",
};

const remoteControlMapping: { [key: number]: string } = {
  0: "disabled [ 0 ]",
  1: "[H3 & H3 Pro] ESGP Negative for negative power | User export BAT + PV -> GRID for positive power [ 100 ]",
  5: "[H3 Only] ESGP Positive for positive power[ 101 ]",
  3: "[H3 Pro Only] Battery import [ 110 ]",
  110: "[H3 Pro Only] Battery export [ 110 BIN ]",
};

const onOffMapping: { [key: number]: string } = {
  0: "Off [ 0 ]",
  1: "On [ 1 ]",
};

const gridCodes: { [key: number]: string } = {
  4: "Netherlands (EN50549_NL)",
  6: "Germany (VDE0126)",
  7: "Germany (VDE4105_DE)",
  14: "Poland (PN-EN50438)",
  15: "Portugal (EN50549_PT)",
  16: "Czech Republic (PPDS_CR)",
  17: "Spain (UNE-206_SP)",
  18: "Spain (RD1699_SP)",
  33: "Austria",
  34: "Switzerland",
  35: "Slovenia",
  38: "Croatia",
  40: "Cyprus",
  41: "Bulgaria",
  42: "Romania",
  65: "Italy (CEI021_B)",
  66: "Italy (CEI021_Areti_A)",
  67: "Italy (CEI021_Areti_B)",
  69: "Spain",
  75: "Germany (VDE4110_DE)",
  79: "Italy (CEI016)",
  81: "Denmark (Denmark 3.2.2)",
  82: "Denmark (TR 3.3.1-DK1)",
  83: "Denmark (TR 3.3.1-DK2)",
};
