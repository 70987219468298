import DatePicker from "react-datepicker";
import { Control, FieldError, useController, UseFormRegisterReturn, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./datepicker.css";

type PickerType = "day" | "month" | "year";

function getDateFormat(type: PickerType) {
  switch (type) {
    case "day":
      return "dd.MM.yyyy";
    case "month":
      return "MM.yyyy";
    case "year":
      return "yyyy";
  }
}

export default function DatePickerInput(props: {
  label: string;
  placeholder?: string;
  register: UseFormRegisterReturn;
  control: Control<any>;
  error?: FieldError;
  type: PickerType;
}) {
  const hasError = props.error?.message;
  const { field } = useController({ name: props.register.name, control: props.control });
  const [startDate, setStartDate] = useState<Date | null>(new Date());

  const onChange = (date: Date | null) => {
    setStartDate(date);
    field.onChange(date ? date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() : null);
  };

  const watch = useWatch({ name: props.register.name, control: props.control });
  useEffect(() => {
    if (watch) {
      const [year, month, day] = watch.split("-");
      setStartDate(new Date(Number(year), Number(month) - 1, Number(day)));
    }
  }, [watch]);

  return (
    <>
      <label htmlFor={props.register.name} className="block text-sm font-medium">
        {props.label}
      </label>
      <div
        className={
          "mt-1  w-full rounded border border-brand-200 focus-within:border-ngen dark:border-brand-800 " +
          (hasError ? "border-red-600" : "border-transparent")
        }
      >
        <DatePicker
          ref={props.register.ref}
          selected={startDate}
          onChange={onChange}
          fixedHeight
          showMonthYearPicker={props.type === "month"}
          showYearPicker={props.type === "year"}
          dateFormat={getDateFormat(props.type)}
          showPopperArrow={false}
          popperPlacement="bottom-start"
          todayButton="Today"
          className={
            "block w-full overflow-hidden border-none bg-brand-100  p-1.5 text-sm focus:border-ngen focus:ring-0  dark:bg-brand-900"
          }
          placeholderText={props.placeholder}
          autoComplete="off"
          wrapperClassName="w-full"
        />
      </div>
      {hasError && <div className="mt-0.5 text-xs font-light text-red-600">{props.error?.message}</div>}
    </>
  );
}
