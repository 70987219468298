import { PermanentUserPreferences } from "../userPeferencesService/userPreferencesService";

export enum RestProtocol {
  HTTP = "http",
  HTTPS = "https",
}

export enum WsProtocol {
  WS = "ws",
  WSS = "wss",
}

export enum RestEnv {
  ACCESS = "ACCESS",
  DATA = "DATA",
  CUSTOM = "CUSTOM",
  HISTORY = "HISTORY",
}

export interface IResult {
  isOk: boolean;
  data: any;
  errorDetails: any;
  wasCanceled: boolean;
  _ts: number | undefined;
}

export interface IQueryParams {
  [key: string]: string | number | boolean;
}

export interface IConnectionURL {
  url: string;
  type: "byUrl";
}

export interface IConnectionDetails {
  protocol: RestProtocol;
  host: string;
  port: number;
  type: "byDetail";
}

type IConnectionMode = "cors" | "no-cors";

export interface IConnection {
  route?: string;
  path: string;
  env: RestEnv;
  mode?: IConnectionMode;
  queryParams?: IQueryParams;
  customEnv?: IConnectionURL | IConnectionDetails;
}

function _concatSearchParamsToUrl(url: string, queryParams: any) {
  let newUrl = url;
  const searchParams = Object.entries(queryParams || {})
    .map(([key, value]) => encodeURIComponent(key) + "=" + encodeURIComponent(value as string | number))
    .join("&");

  if (searchParams) {
    newUrl += `?${searchParams}`;
  }

  return newUrl;
}

export function _getRequestConnectionURL(connection: IConnection): string {
  const { route = "", path, env, queryParams = {}, customEnv } = connection;

  let url = getEnvUrl("REST", env, customEnv);

  url += route + path;
  url = _concatSearchParamsToUrl(url, queryParams);

  return url;
}

function _getCurrentRestProtocol(): RestProtocol {
  const protocol = window.location.protocol;

  if (protocol === "https:") {
    return RestProtocol.HTTPS;
  } else {
    return RestProtocol.HTTP;
  }
}

function _getCurrentWsProtocol(): WsProtocol {
  const protocol = window.location.protocol;

  if (protocol === "https:") {
    return WsProtocol.WSS;
  } else {
    return WsProtocol.WS;
  }
}

function _getEnvUrlFromCustomEnv(type: "REST" | "WS", env: RestEnv): string {
  let url = "";

  const protocol =
    (window as any)?._CONNECTION?.[`${type}_${env}_PROTOCOL`] ??
    process.env[`REACT_APP_${type}_${env}_PROTOCOL`] ??
    (type === "REST" ? _getCurrentRestProtocol() : _getCurrentWsProtocol());
  const host =
    (window as any)?._CONNECTION?.[`${type}_${env}_HOST`] ?? process.env[`REACT_APP_${type}_${env}_HOST`] ?? window.location.hostname;
  const port = (window as any)?._CONNECTION?.[`${type}_${env}_PORT`] ?? process.env[`REACT_APP_${type}_${env}_PORT`] ?? 80;

  url = `${protocol}://${host}:${port}`;

  return url;
}

export function getEnvUrl(type: "REST" | "WS", env: RestEnv, customEnv?: IConnectionURL | IConnectionDetails) {
  let url = null;

  if (env === RestEnv.CUSTOM) {
    if (!customEnv) {
      throw new Error("Undefined environment!");
    } else {
      switch (customEnv.type) {
        case "byUrl":
          url = customEnv.url;
          break;

        case "byDetail":
          url = `${customEnv.protocol}://${customEnv.host}:${customEnv.port}`;
          break;

        default:
          throw new Error("Unknown connection type!");
      }
    }
  } else {
    const predefinedUrl = (window as any)?._CONNECTION?.[`${type}_${env}_URL`] ?? process.env[`REACT_APP_${type}_${env}_URL`] ?? undefined;

    if (predefinedUrl) {
      url = predefinedUrl;
    } else {
      url = _getEnvUrlFromCustomEnv(type, env);
    }
  }

  return url;
}

export function _fetch(requestURL: string, requestMeta: any) {
  let _ts: number | undefined = undefined;
  return fetch(requestURL, requestMeta)
    .then(async function (res) {
      const isJson = res.headers.get("content-type")?.includes("application/json");
      _ts = Date.now();
      if (!res.ok) {
        if (isJson) {
          throw await res.json();
        } else {
          throw await res.text();
        }
      } else {
        if (isJson) {
          return res.json();
        } else {
          const msg = await res.text();
          return {
            text: msg,
          };
        }
      }
    })
    .then(
      (result): IResult => {
        if (result.status === "failed") {
          if (result.reason === "Token expired") {
            PermanentUserPreferences.purge("", "token");
            window.location.reload();
          }

          return {
            errorDetails: result.reason || "Fetched request failed!",
            isOk: false,
            data: undefined,
            wasCanceled: false,
            _ts,
          };
        }

        return {
          data: result,
          isOk: true,
          errorDetails: undefined,
          wasCanceled: false,
          _ts,
        };
      },
      (error): IResult => {
        return {
          errorDetails: error,
          isOk: false,
          data: undefined,
          wasCanceled: error?.code != null && error?.code !== 20,
          _ts,
        };
      }
    );
}
