import Icon from "@mdi/react";
import logoSrc from "../../assets/sgconnect_logo.jpg";
import TextInput from "../../components/inputs/textInput/TextInput";
import { LoadingIcon } from "../../decorators/loading/Loading";
import { mdiLogin } from "@mdi/js";
import { useForm } from "react-hook-form";

type IFormData = { username: string; password: string };
export function UserLoginForm(props: { logUserIn: (user: string, pass: string) => void; isLoading: boolean }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormData>({
    criteriaMode: "firstError",
    defaultValues: { username: process.env.REACT_APP_USERNAME || undefined, password: process.env.REACT_APP_PASSWORD || undefined },
  });

  const onSubmit = (data: IFormData) => {
    props.logUserIn(data.username, data.password);
  };

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-gradient-to-tl from-brand-950  to-brand-50 dark:to-brand-500 ">
      <div className="flex min-h-full w-full flex-col justify-center p-4 transition-all">
        <div className="mx-auto w-full max-w-sm">
          <div className="rounded bg-brand-50 bg-opacity-80 p-6 shadow drop-shadow-sm dark:bg-brand-950">
            <div
              className="mb-8 rounded border border-brand-600 border-opacity-50 p-4 shadow-md shadow-brand-600"
              style={{ background: "black" }}
            >
              <img className="mx-auto w-auto" src={logoSrc} alt="NGEN logo" />
              <div className="-mt-4 pr-4 text-right font-semibold uppercase text-ngen">Nexus</div>
            </div>
            <form className="space-y-4">
              <TextInput
                type="text"
                label="Username"
                register={register("username", { required: "Username is required!" })}
                error={errors.username}
              />

              <TextInput
                label="Password"
                type="password"
                register={register("password", { required: "Password is required!" })}
                error={errors.password}
              />

              <div className="pt-2">
                <button
                  onClick={handleSubmit(onSubmit)}
                  disabled={props.isLoading}
                  className="flex w-full items-center justify-center gap-2 rounded border border-transparent bg-ngen px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-500  "
                >
                  {props.isLoading ? <LoadingIcon /> : <Icon path={mdiLogin} className="h-5 w-5" />}
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
