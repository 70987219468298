import Container from "../../../../../decorators/container/Container";
import { IDevice } from "../../../../../types/Device";
import { mdiHandshake } from "@mdi/js";
import { Value } from "../_shared/Value";

export function AboutPartner(props: { device: IDevice }) {
  if (!props.device.project_partner) {
    return null;
  }

  return (
    <Container title="Install partner" icon={mdiHandshake} isCollapseable>
      <div className="flex flex-col gap-2">
        <Value label="Partner name" value={props.device.project_partner.name} />
        <Value label="Partner email" value={props.device.project_partner.email} />
      </div>
    </Container>
  );
}
