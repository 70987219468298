export function StationStatus(props: { status: string }) {
  const statusDef = {
    color: "red",
    bg: "red",
    statusColor: "red",
    text: "unknown",
  };

  switch (props.status) {
    case "A":
      statusDef.color = "text-green-700 dark:text-green-200";
      statusDef.bg = "bg-green-100 dark:bg-green-800/30";
      statusDef.text = "Active";
      statusDef.statusColor = "fill-green-500 dark:fill-green-300";
      break;
    case "I":
      statusDef.color = "text-orange-700 dark:text-orange-200";
      statusDef.bg = "bg-orange-100 dark:bg-orange-800/30";
      statusDef.statusColor = "fill-orange-500 dark:fill-orange-300";
      statusDef.text = "Inactive";
      break;
    case "B":
      statusDef.color = "text-red-700 dark:text-red-200";
      statusDef.bg = "bg-red-100 dark:bg-red-800/30";
      statusDef.statusColor = "fill-red-500 dark:fill-red-300";
      statusDef.text = "Blocked";
      break;

    default:
      statusDef.color = "text-gray-700";
      statusDef.bg = "bg-gray-100";
      statusDef.statusColor = "fill-gray-500";
      statusDef.text = props.status === "N/A" ? "Unknown" : props.status.toString().toUpperCase();
      break;
  }

  return (
    <div className={`flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-xs font-medium  ${statusDef.color} ${statusDef.bg}`}>
      <svg className={`h-1.5 w-1.5 ${statusDef.statusColor}`} viewBox="0 0 6 6" aria-hidden="true">
        <circle cx={3} cy={3} r={3} />
      </svg>
      {statusDef.text}
    </div>
  );
}
