import { Transition, TransitionChild } from "@headlessui/react";
import { Fragment, forwardRef, useImperativeHandle, useState } from "react";
import { NOTIFICATION_TIMEOUT } from "./utils/const";

export type INotificationProps = {
  count: number;
  autoClose?: boolean;
};

type INotificationState = {
  isOpen: boolean;
} & INotificationProps;

export type INotificationRef = {
  open: (props: INotificationProps) => void;
  close: () => void;
};

export const Notification = forwardRef<INotificationRef>((_, ref) => {
  const [state, setState] = useState<INotificationState>({ isOpen: false, count: 0 });

  useImperativeHandle(ref, () => ({
    open,
    close,
  }));

  const open = (props: INotificationProps) => {
    setState({ ...props, isOpen: true });

    if (props.autoClose) {
      setTimeout(close, NOTIFICATION_TIMEOUT);
    }
  };

  const close = () => {
    setState((prevState) => ({ ...prevState, isOpen: false }));
  };

  return (
    <Transition show={state.isOpen} as={Fragment}>
      <div className="relative z-50">
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-brand-950 bg-opacity-25 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative">
                <TransitionChild
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="scale-50"
                  enterTo="scale-110"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="scale-110"
                  leaveTo="scale-150"
                >
                  <div className="text-9xl text-white">+ {state.count}</div>
                </TransitionChild>
              </div>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Transition>
  );
});
