import classes from "./NgenStarProBadge.module.css";
import starImg from "./star.png";
import { useState } from "react";

export function NgenStarProBadge() {
  const [state, setState] = useState<{
    x: number;
    y: number;
    key: number;
  }>({
    x: 215,
    y: 965,
    key: Date.now(),
  });

  return (
    <g id="ngenStarProPackage">
      <defs>
        <linearGradient id="Gradient2" x1="0" x2="0" y1="0" y2="1">
          <stop offset="5%" stopColor="#ffe5b2" stopOpacity="1" />
          <stop offset="30%" stopColor="#d4b77a" stopOpacity="1" />
          <stop offset="50%" stopColor="#e6cc99" stopOpacity="1" />
          <stop offset="70%" stopColor="#d4b77a" stopOpacity="1" />
        </linearGradient>
      </defs>
      <text
        fill="url(#Gradient2)"
        fontFamily="inherit"
        fontSize="36"
        transform="skewY(-14)"
        x={210}
        y={1070}
        textAnchor="middle"
        style={{
          filter: "drop-shadow( 0px 0px 4px #644c1b55)",
        }}
      >
        PRO
      </text>
      <image
        key={state.key}
        className={classes.glitter}
        width="50"
        height="50"
        x={state.x}
        y={state.y}
        xlinkHref={starImg}
        onAnimationEnd={() => {
          setState({
            x: getRandomNumber(160, 215),
            y: getRandomNumber(965, 995),
            key: Date.now(),
          });
        }}
      />
    </g>
  );
}

function getRandomNumber(min: number, max: number) {
  return Math.random() * (max - min) + min;
}
