import DotSpinner from "./dotSpinner/DotSpinner";
import LottieAnimation from "./LottieAnimation";
import React, { useEffect, useState } from "react";
import solarImg from "./assets/solarImg.png";
import solarIn from "./assets/solarIn.json";
import solarOut from "./assets/solarOut.json";
import { getTimeOfDay, TimeOfDay } from "./helpers/SunCalcModule";
import { SmartMeter } from "./types/AnimationsTypes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface AnimationProps {
  className?: string;
  opacity?: number;
  smartMeter: SmartMeter;
}

const AnimationSolar: React.FC<AnimationProps> = ({ className, opacity, smartMeter }) => {
  const { t } = useTranslation();
  const formatPower = (value: any) => {
    const kWValue = Math.abs(value / 1000).toFixed(1);
    return parseFloat(kWValue) % 1 === 0 ? parseFloat(kWValue).toFixed(0) : kWValue;
  };
  const navigate = useNavigate();
  const energyGrid = () => {
    navigate("/energy?id=4");
  };
  const [timeOfDay, setTimeOfDay] = useState<TimeOfDay | null>(null);
  const getFormattedTimestamp = () => {
    let date = new Date();
    return date.toLocaleString("sl-SI");
  };
  const shouldDisplayTimestamp = () => {
    return smartMeter.power !== null;
  };
  useEffect(() => {
    try {
      const position = { latitude: 46.0569, longitude: 14.5058 }; // Ljubljana
      const time = getTimeOfDay(position);
      setTimeOfDay(time);
    } catch (error) {
      console.error("Error getting time of day:", error);
    }
  }, [smartMeter]);
  return (
    <div className={"sky py-3 " + (timeOfDay || "")}>
      <div
        id="animationHome"
        className={`relative ${className ? className : ""} animationHome`}
        style={{ height: "auto", opacity: opacity }}
      >
        {(smartMeter.power || 0) < 0 && <LottieAnimation id="solarOut" animationData={solarOut} />}
        {(smartMeter.power || 0) > 0 && <LottieAnimation id="solarIn" animationData={solarIn} />}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="100%"
          height="100%"
          viewBox="0 250 1082 1260"
        >
          <defs>
            <clipPath id="dchda">
              <path
                fill="#fff"
                d="M818.448 992c6.351 0 11.5 5.148 11.5 11.5 0 6.351-5.149 11.5-11.5 11.5s-11.5-5.149-11.5-11.5c0-6.352 5.149-11.5 11.5-11.5z"
              />
            </clipPath>
          </defs>
          <g id="timestamp">
            <text fill="white" fontFamily="inherit" fontSize="28" transform="translate(796 1480)">
              {shouldDisplayTimestamp() && <tspan dy="0.8em">{getFormattedTimestamp()}</tspan>}
            </text>
          </g>
          <g>
            <g>
              <image width="1082" height="1260" transform="translate(0 191)" xlinkHref={solarImg} />
            </g>
            <g>
              <g id="gridLine">
                <path fill="#7e8a8a" d="M819 1260h-1v-250h1z" transform="translate(-50 250)" />
              </g>
              <g id="gridDot">
                <path
                  fill="#7e8a8a"
                  d="M818.448 992c6.351 0 11.5 5.148 11.5 11.5 0 6.351-5.149 11.5-11.5 11.5s-11.5-5.149-11.5-11.5c0-6.352 5.149-11.5 11.5-11.5z"
                  transform="translate(-50 255)"
                />
                <path
                  fill="none"
                  stroke="#5eba47"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit="20"
                  strokeWidth="12"
                  d="M818.448 992c6.351 0 11.5 5.148 11.5 11.5 0 6.351-5.149 11.5-11.5 11.5s-11.5-5.149-11.5-11.5c0-6.352 5.149-11.5 11.5-11.5z"
                  clipPath='url("#dchda")'
                  transform="translate(-50 255)"
                />
              </g>
              <g
                id="gridOpen"
                onClick={() => {
                  energyGrid();
                }}
              >
                <g>
                  <g>
                    {/* <g transform="translate(835 1100)"> */}
                    <g transform="translate(620 1460)">
                      {smartMeter.power === null ? (
                        <DotSpinner />
                      ) : (
                        <text
                          fill="#fff"
                          dominantBaseline="text-before-edge"
                          fontFamily="inherit"
                          fontSize="3rem"
                          transform="translate(125 -30)"
                          textAnchor="end"
                        >
                          <tspan dy="0.5em">{formatPower(smartMeter["power"])} kW</tspan>
                        </text>
                      )}
                    </g>
                  </g>
                  <g>
                    <g>
                      <text
                        fill="#5eba47"
                        dominantBaseline="text-before-edge"
                        fontFamily="inherit"
                        fontSize="2rem"
                        transform="translate(745 1380)"
                        textAnchor="end"
                      >
                        <tspan dy="0.8em">{t("animation.grid")}</tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default AnimationSolar;
