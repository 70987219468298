import { FieldError, UseFormRegisterReturn } from "react-hook-form";

type TextType = {
  type: "text" | "password";
};

type NumberType = {
  type: "number";
  min?: number;
  max?: number;
};

export default function TextInput(
  props: {
    label: string;
    placeholder?: string;
    register: UseFormRegisterReturn;
    error?: FieldError;
    suffix?: string;
  } & (TextType | NumberType)
) {
  const hasError = props.error?.message;

  return (
    <div>
      <label htmlFor={props.register.name} className="block text-sm font-medium">
        {props.label}
      </label>
      <div
        className={
          "relative mt-1 flex overflow-hidden rounded border-b focus-within:border-ngen " +
          (hasError ? "border-red-600" : "border-brand-300 dark:border-brand-700")
        }
      >
        <input
          type={props.type || "text"}
          min={props.type === "number" ? props.min : undefined}
          max={props.type === "number" ? props.max : undefined}
          {...props.register}
          className={
            "block w-full border-0 border-b border-transparent bg-brand-100 text-sm focus:border-ngen focus:ring-0 dark:bg-brand-900 " +
            (props.suffix ? "pr-12" : "")
          }
          placeholder={props.placeholder}
          autoComplete="off"
        />
        {props.suffix && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex w-10 items-center justify-center bg-brand-200 dark:bg-brand-800">
            <span className="text-sm text-brand-700 dark:text-brand-200">{props.suffix}</span>
          </div>
        )}
      </div>
      {hasError && <div className="mt-0.5 text-xs font-light text-red-600">{props.error?.message}</div>}
    </div>
  );
}
