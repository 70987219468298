import { clsx } from "../../utils/clsx";
import type { ICountry } from "./types/types";
import { generateDeviceName } from "./utils/utils";
import { useInView } from "react-intersection-observer";

export function CountryCard(props: { data: ICountry; useGifs: boolean }): React.JSX.Element {
  const { ref, inView } = useInView({
    delay: 500,
    rootMargin: "0px 200px 0px 200px",
  });

  return (
    <div className="mx-3 w-[400px] pb-2" ref={ref}>
      {inView && <CountryCardContent data={props.data} useGifs={props.useGifs} />}
    </div>
  );
}

function CountryCardContent(props: { data: ICountry; useGifs: boolean }): React.JSX.Element {
  return (
    <div className={`flex flex-col gap-5 bg-brand-950 p-6 shadow-lg`}>
      <div>
        <div className="flex items-end justify-between">
          <div className="-ml-3 -mt-2">
            {/* xxx: if useGifs is true, use the gif source, otherwise use the video source */}
            {props.data.flagSrc?.video ? (
              <video src={props.data.flagSrc?.video} autoPlay muted loop style={{ width: "auto", height: "80px" }} />
            ) : (
              <div className="h-20" />
            )}
          </div>
          <div className="text-right text-xxs leading-3 text-ngen">
            ALL <br /> DEVICES
          </div>
        </div>
        <div className="flex justify-between text-2xl">
          <div className="uppercase">{props.data.name}</div>
          <div className="text-ngen">{props.data.total}</div>
        </div>
      </div>

      <Divider />

      <div className="text-sm">
        <div className="mb-2 text-ngen">Device types</div>
        {Object.entries(props.data.byDevice).map(([device, data]) => (
          <div key={device} className="flex justify-between">
            <span className="uppercase text-brand-500">{generateDeviceName(device)}</span>
            <span>{data.total}</span>
          </div>
        ))}
      </div>

      <Divider />

      <div>
        <div className="mb-2 text-sm text-ngen">Live data</div>
        <table className="w-full border-collapse text-xs">
          <thead>
            <tr>
              <th></th>
              <th className="text-brand-500">THIS WEEK</th>
              <th className="text-brand-500">THIS MONTH</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(props.data.byDevice).map(([device, data]) => (
              <tr key={device}>
                <td className="uppercase leading-3">{generateDeviceName(device)}</td>
                <td
                  className={clsx(
                    "border border-brand-800 bg-brand-900 text-center normal-nums leading-8",
                    data.thisWeek ? "text-2xl" : "text-xl text-brand-500",
                  )}
                >
                  + {data.thisWeek}
                </td>
                <td
                  className={clsx(
                    "border border-brand-800 bg-brand-900 text-center normal-nums leading-8",
                    data.thisMonth ? "text-2xl" : "text-xl text-brand-500",
                  )}
                >
                  + {data.thisMonth}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function Divider(): React.JSX.Element {
  return <div className="h-0.5 bg-brand-900" />;
}
