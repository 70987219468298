import moment from "moment-timezone";
import StatusContainer from "../../../../../_common/StatusContainer";
import StatusValue from "../../../../../_common/StatusValue";
import { getStateContainerClass } from "../../utils";
import { mdiCreationOutline, mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";
import { useId, useState } from "react";
import { useMqttProperty } from "../../../../../../../hooks/useMqttClient";

export default function SmartGridState() {
  const componentId = useId();
  const [state, setState] = useState<{
    isLoading: boolean;
    enabled: boolean | undefined;
    dateTime: string;
  }>({
    isLoading: true,
    enabled: undefined,
    dateTime: "N/A",
  });

  useMqttProperty(
    "smartgrid",
    componentId,
    10_000,
    (data: {
      enabled?: boolean;
      metadata?: {
        utc_timestamp: number;
      };
    }) => {
      return setState({
        dateTime:
          data.metadata?.utc_timestamp != null
            ? moment.tz(data.metadata.utc_timestamp * 1000, "Europe/Ljubljana").format("DD.MM.yyyy HH:mm:ss")
            : "N/A",
        enabled: data.enabled,
        isLoading: false,
      });
    }
  );

  return (
    <StatusContainer
      title="Smart grid state"
      dateTime={state.dateTime}
      icon={mdiCreationOutline}
      className={getStateContainerClass("grid")}
    >
      <StatusValue
        isLoading={state.isLoading}
        value={state.enabled != null ? statusMapping[state.enabled.toString()] : "N/A"}
        description="Status"
        icon={state.enabled ? mdiToggleSwitch : mdiToggleSwitchOffOutline}
        userEditable
        editable={{
          type: "comboBoxInput",
          dataValue: state.enabled,
          dataKey: "smartgrid",
          options: Object.entries(statusMapping).map(([key, value]) => ({ key, value: value.toUpperCase() })),
        }}
      />
    </StatusContainer>
  );
}

const statusMapping: { [key: string]: string } = {
  true: "Enabled",
  false: "Disabled",
};
