import BatteryConfigState from "./states/ngenStar/batteryState/BatteryConfigState";
import BatteryState from "./states/ngenStar/batteryState/BatteryState";
import Container from "../../../../decorators/container/Container";
import GridState from "./states/ngenStar/gridState/GridState";
import History from "./history/History";
import Icon from "@mdi/react";
import InverterState from "./states/ngenStar/inverterState/InverterState";
import SiteState from "./states/ngenStar/siteState/siteState";
import SmartGridState from "./states/ngenStar/smartGridState/SmartGridState";
import SolarState from "./states/ngenStar/solarState/SolarState";
import SolarStatePwrBank from "./states/powerbank/solarState/SolarState";
import { IDevice } from "../../../../types/Device";
import { Fragment, ReactNode } from "react";
import {
  mdiArrowCollapseVertical,
  mdiBattery80,
  mdiBatterySync,
  mdiChartLine,
  mdiChip,
  mdiCreationOutline,
  mdiInformationBoxOutline,
  mdiLinkBoxVariantOutline,
  mdiSolarPanelLarge,
  mdiTransmissionTower,
} from "@mdi/js";
import OpStatePwrBank from "./states/powerbank/opState/OpState";

type IDeviceFunctionality = {
  id: string;
  icon: string;
  content: ReactNode;
};

function getDeviceFunctionalities(device: IDevice): IDeviceFunctionality[] {
  const deviceType = device.device?.type;
  const elements: IDeviceFunctionality[] =
    deviceType === "ngenstar"
      ? [
          { id: "Inverter state", icon: mdiChip, content: <InverterState /> },
          { id: "Grid state", icon: mdiTransmissionTower, content: <GridState /> },
          { id: "Solar state", icon: mdiSolarPanelLarge, content: <SolarState /> },
          { id: "Battery state", icon: mdiBattery80, content: <BatteryState /> },
          { id: "Battery settings", icon: mdiBatterySync, content: <BatteryConfigState /> },
          { id: "Site limits", icon: mdiArrowCollapseVertical, content: <SiteState device={device} /> },
        ]
      : [];

  if (deviceType === "powerbank") {
    elements.push({ id: "OP state", icon: mdiInformationBoxOutline, content: <OpStatePwrBank /> });
    elements.push({ id: "Solar state", icon: mdiSolarPanelLarge, content: <SolarStatePwrBank /> });
  }

  if (deviceType === "ngenstar" || deviceType === "smart-meter") {
    elements.push({ id: "Smart grid state", icon: mdiCreationOutline, content: <SmartGridState /> });
  }

  elements.push({
    id: "History",
    icon: mdiChartLine,
    content: <History device={device} />,
  });

  return elements;
}

export default function DeviceDetails(props: { device: IDevice }) {
  const deviceFunctionalities = getDeviceFunctionalities(props.device);

  return (
    <div className="flex w-full flex-col gap-3">
      {deviceFunctionalities.length > 2 && <ShortcutsToElements deviceFunctionalities={deviceFunctionalities} />}

      {deviceFunctionalities.map((c) => (
        <Fragment key={c.id}>{c.content}</Fragment>
      ))}
    </div>
  );
}

function ShortcutsToElements(props: { deviceFunctionalities: IDeviceFunctionality[] }) {
  return (
    <Container
      title="Shortcuts"
      hideTitle
      className={
        "sticky top-14 z-10 rounded border border-l-4 border-brand-100 bg-brand-50 p-4 shadow shadow-brand-100 dark:border-brand-800 dark:bg-brand-900 dark:shadow-brand-800"
      }
    >
      <div id="shortcutsBar" className="flex flex-wrap gap-4">
        {props.deviceFunctionalities.map((df) => (
          <Shortcut key={df.id} id={df.id} icon={df.icon} />
        ))}
      </div>
    </Container>
  );
}

function Shortcut(props: { id: string; icon?: string }) {
  return (
    <div
      className="flex items-center gap-1 font-medium leading-none text-ngen hover:cursor-pointer hover:underline"
      onClick={() => scrollToElement(props.id)}
    >
      <Icon path={props.icon ?? mdiLinkBoxVariantOutline} className="-mb-0.5 h-4 w-4" />
      {props.id}
    </div>
  );
}

function scrollToElement(id: string) {
  const element = document.getElementById(id);
  const shortcutsBar = document.getElementById("shortcutsBar");

  if (element && shortcutsBar) {
    window.scroll({
      top: element.getBoundingClientRect().top + window.scrollY - 116 - shortcutsBar.clientHeight,
      behavior: "smooth",
    });
  }
}
