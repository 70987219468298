import Container from "../../../../../decorators/container/Container";
import Icon from "@mdi/react";
import { DeviceStatus } from "../../../../deviceList/_common/DeviceStatus";
import { getDeviceTypeDefinition } from "../../../../deviceList/deviceListContent/DeviceListContent.utils";
import { IDevice } from "../../../../../types/Device";
import { mdiChip } from "@mdi/js";
import { Value } from "../_shared/Value";

export default function AboutCommunicator({ device }: { device: IDevice }) {
  const devDef = getDeviceTypeDefinition(device);

  return (
    <Container title="Communicator" icon={mdiChip} isCollapseable>
      <div className="flex flex-col gap-2">
        <Value label="Device status" value={<DeviceStatus status={device.online} />} />
        <Value label="Device last time online" value={device.last_online} />
        <Value label="Device ID" value={device.device.id} />
        <Value
          label="Device type"
          value={
            <div className="flex h-full items-center gap-1">
              {devDef.icon && <Icon path={devDef.icon} className={"-mb-0.5 h-4 w-4 " + devDef.classNameColor} />}
              {devDef.name}
            </div>
          }
        />
        <Value label="Device IP" value={device.device.ip} />
        <Value label="Software version" value={device.software_version} />
        <Value label="Meter serial no." value={device.meter_serial_number} />
        <Value label="Quality control date" value={device.quality_control_date} />
        <Value label="Quality control performed by" value={device.quality_control_performed_by || "N/A"} />
      </div>
    </Container>
  );
}
