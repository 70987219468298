import { MQTTConnectionProvider } from "./mqttConnectionProvider/MQTTConnectionProvider";
import Topbar from "./topbar/Topbar";
import { Outlet } from "react-router-dom";

export function Layout() {
  return (
    <MQTTConnectionProvider>
      <Topbar />

      <div className="mt-10 flex w-full justify-center">
        <Outlet />
      </div>
    </MQTTConnectionProvider>
  );
}
