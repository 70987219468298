import moment from "moment-timezone";
import StatusContainer from "../../../../../_common/StatusContainer";
import StatusValue from "../../../../../_common/StatusValue";
import { getStateContainerClass } from "../../utils";
import { mdiNumeric1BoxOutline, mdiNumeric2BoxOutline, mdiNumeric3BoxOutline, mdiSigma, mdiTransmissionTower } from "@mdi/js";
import { useId, useState } from "react";
import { useMqttProperty } from "../../../../../../../hooks/useMqttClient";

export default function GridState() {
  const componentId = useId();
  const [state, setState] = useState<{
    isLoading: boolean;
    phasePower: {
      L1: number | undefined;
      L2: number | undefined;
      L3: number | undefined;
      _total: number | undefined;
    };
    dateTime: string;
  }>({
    isLoading: true,
    phasePower: {
      L1: undefined,
      L2: undefined,
      L3: undefined,
      _total: undefined,
    },
    dateTime: "N/A",
  });

  useMqttProperty(
    "grid",
    componentId,
    10_000,
    (data: {
      power: {
        L1: number;
        L2: number;
        L3: number;
      };
      metadata: {
        utc_timestamp: number;
      };
    }) => {
      return setState({
        phasePower: {
          L1: data.power.L1,
          L2: data.power.L2,
          L3: data.power.L3,
          _total:
            data.power.L1 != null || data.power.L2 != null || data.power.L3 != null
              ? (data.power.L1 || 0) + (data.power.L2 || 0) + (data.power.L3 || 0)
              : undefined,
        },

        dateTime:
          data.metadata.utc_timestamp != null
            ? moment.tz(data.metadata.utc_timestamp * 1000, "Europe/Ljubljana").format("DD.MM.yyyy HH:mm:ss")
            : "N/A",
        isLoading: false,
      });
    }
  );

  return (
    <StatusContainer title="Grid state" dateTime={state.dateTime} icon={mdiTransmissionTower} className={getStateContainerClass("grid")}>
      <StatusValue
        isLoading={state.isLoading}
        value={state.phasePower.L1}
        description="Grid phase L1 power"
        icon={mdiNumeric1BoxOutline}
        unit="W"
      />
      <StatusValue
        isLoading={state.isLoading}
        value={state.phasePower.L2}
        description="Grid phase L2 power"
        icon={mdiNumeric2BoxOutline}
        unit="W"
      />
      <StatusValue
        isLoading={state.isLoading}
        value={state.phasePower.L3}
        description="Grid phase L3 power"
        icon={mdiNumeric3BoxOutline}
        unit="W"
      />
      <StatusValue isLoading={state.isLoading} value={state.phasePower._total} description="Total grid power" icon={mdiSigma} unit="W" />
    </StatusContainer>
  );
}
